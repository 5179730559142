<template>
    <div class="y-scrollable-block position-relative overflow-auto" v-if="data">
        <table class="table table-bordered table-has-sticky table-sticky-row table-layout-fixed">
            <thead>
            <tr>
                <th><sortable-col-header col="name" v-model="sorting">Name</sortable-col-header></th>
                <th><sortable-col-header col="company" v-model="sorting">Company</sortable-col-header></th>
                <th><sortable-col-header col="country.name" v-model="sorting">Country</sortable-col-header></th>
                <th>Address</th>
                <th>Phone</th>
                <th>E-mail</th>
                <th></th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="lawyer in data">
                <td>{{ lawyer.name }}</td>
                <td>{{ lawyer.company }}</td>
                <td>{{ lawyer.country.name }}</td>
                <td>{{ lawyer.address }}</td>
                <td>{{ lawyer.phone }}</td>
                <td>{{ lawyer.email }}</td>
                <td>
                    <button class="btn btn-sm btn-secondary btn-icon" @click="goToEdit(lawyer)">
                        <inline-svg src="/assets/icons/pencil.svg" />
                    </button>
                    <button class="btn btn-sm btn-danger btn-icon" @click="deleteLawyer(lawyer)">
                        <inline-svg src="/assets/icons/trash.svg" />
                    </button>
                </td>
            </tr>

            </tbody>
        </table>
    </div>
    <div v-else class="no-data-available">
        No lawyers added
    </div>
</template>

<script>
export default {
    props: {
        data: {
            required: true,
            type: Array,
        },
        showActions: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            sorting: null,
        };
    },
    watch: {
        sorting() {
            this.$emit('sort', this.sorting);
        },
    },
    methods: {
        goToEdit(lawyer) {
            return this.$router.push({name: 'legal.lawyer.edit', params: {id: lawyer.id}});
        },
        deleteLawyer(lawyer) {
            this.$emit('delete', lawyer);
        },
    },
};
</script>
