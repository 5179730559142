<template>
    <span :class="`wrapper ${dir}`" @click="sort">
        <slot></slot>
    </span>
</template>

<style scoped>
.wrapper {
    cursor: pointer;
}

.none::after {
    display: inline-block;
    content: "⯁";
    opacity: 0.25;
    transform: scaleY(1.4);
}

.asc::after {
    display: inline-block;
    content: "▲";
}

.desc::after {
    display: inline-block;
    content: "▲";
    transform: rotate(180deg);
}
</style>

<script>
export default {
    props: {
        col: {
            required: true,
            type: String
        },
        value: {
            required: false,
            type: Object
        },
    },
    computed: {
        dir() {
            if (this.value && this.col === this.value.col) {
                return this.value.dir;
            } else {
                return 'none';
            }
        },
    },
    methods: {
        sort: function () {
            this.$emit('input', {
                col: this.col,
                dir: (this.value && this.col === this.value.col && this.value.dir === 'asc')
                    ? 'desc' : 'asc'
            });
        }
    }
};
</script>
