<template>
    <div class="y-scrollable-block position-relative overflow-auto">
        <table class="table table-bordered table-has-sticky table-sticky-row table-sticky-col table-layout-fixed">
            <thead>
            <tr>
                <th>Products</th>
                <th>Launch date</th>
                <th>7F Stock Qty</th>
                <th>Distributor</th>
                <th>Retailer</th>
                <th>Total</th>
            </tr>
            </thead>
            <tbody>
            <time-to-market-table-row :data="data.total">
                <th class="font-weight-normal">Avg days in stock</th>
                <td>{{ null | number }}</td>
            </time-to-market-table-row>

            <template v-for="series in data.series">
                <time-to-market-table-row :data="series.total"
                                         :class="['collapsible-row', { 'collapsible-row-open': series.open }]">
                    <th @click="toggleCollapsed(series)">{{ series.series.name }}</th>
                    <td>{{ null | number }}</td>
                </time-to-market-table-row>

                <template v-if="series.open">
                    <time-to-market-table-row v-for="product in series.products" :data="product.statistics"
                                             :key="product.product.id" class="collapsible-row-open">
                        <th class="font-weight-normal">
                            {{ product.product.product_number }}
                            <br>
                            <span style="font-size:66%">{{ product.product.matchcode }}</span>
                        </th>
                        <td>{{ product.product.launch_date | date }}</td>
                    </time-to-market-table-row>
                </template>
            </template>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
th, td {
    width: 200px;
}
</style>

<script>
import TimeToMarketTableRow from './TimeToMarketTableRow';

export default {
    components: {
        TimeToMarketTableRow,
    },
    props: {
        data: {
            required: true,
            type: Object,
        },
    },
    methods: {
        toggleCollapsed(series) {
            this.$set(series, 'open', ! series.open);
        },
    },
};
</script>
