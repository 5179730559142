<template>
    <div class="tile d-flex flex-column">
        <div class="mb-3">
            <country-rank-chart :data="data" />
        </div>

        <country-rank-table :data="slicedRanks" />

        <a href="#" @click.prevent="showModal">See full list</a>

        <b-modal id="country-rank-modal" scrollable ok-only>
            <div class="py-3 bg-white text-right">
                <a href="#" class="text-primary font-weight-bold"
                   @click="$emit('export')">Export to XLS</a>
            </div>
          <country-rank-table :data="data" />
        </b-modal>
    </div>
</template>

<style scoped>
a {
  text-align: right;
  font-weight: 700;
  color: #E31837;
  margin-top: 5px;
}
</style>

<script>
import CountryRankTable from './tables/CountryRankTable'
import CountryRankChart from './charts/CountryRankChart'

export default {
    components: {
        CountryRankTable,
        CountryRankChart
    },
    props: {
        data: {
            required: false,
            type: Array,
        }
    },
    computed: {
        slicedRanks() {
            if (!this.data) {
                return null;
            }

            return this.data.slice(0, 3);
        }
    },
    methods: {
        showModal() {
            this.$bvModal.show('country-rank-modal');
        }
    }
};
</script>
