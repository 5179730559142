<template>

    <div v-if="ipc">
        <h2>Manage files</h2>

        <h3>Upload new file</h3>

        <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
            <form @submit.prevent="handleSubmit(upload)">
                <ValidationProvider tag="div" class="form-group" name="type" rules="required" v-slot="{ errors }" v-if="typeOptions">
                    <label>* Type</label>
                    <v-select v-model="attachment.type"
                              :options="selectableTypes"
                              label="label"
                              :reduce="type => type.id"
                              :clearable="false"
                              :searchable="false">
                    </v-select>
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider v-if="multiDocTypeSelected" tag="div" class="form-group" name="name" v-slot="{ errors }">
                    <label for="name">{{ nameLabel }}</label>
                    <input id="name" type="text" class="form-control" v-model="attachment.name">
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider v-if="multiDocTypeSelected" tag="div" class="form-group" name="date" v-slot="{ errors }">
                    <label>Date</label>
                    <date-range-picker v-model="attachment.date" :range="false"
                                       class="d-block" :clearable="true">
                    </date-range-picker>
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" ref="fileValProvider" class="form-group" name="file" rules="required" v-if="showFileInput" v-slot="{ validate, errors }">
                    <label for="file">* File</label>
                    <input id="file" type="file" class="form-control" ref="fileInput" @change="validate">
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <div class="form-group mb-0">
                    <button type="submit" class="btn btn-primary btn-block">
                        Upload attachment
                    </button>
                </div>
            </form>
        </ValidationObserver>

        <h3 class="mt-4">Uploaded files</h3>

        <table class="table table-bordered table-layout-fixed">
            <colgroup>
                <col style="width: 150px;">
                <col />
                <col style="width: 150px;">
            </colgroup>
            <thead>
            <tr>
                <th>Type</th>
                <th>Name</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="attachment in ipc.attachments">
                <td><strong>{{ typeOptions.find(t => t.id === attachment.type).label }}</strong></td>
                <td>{{ attachment.name }}</td>
                <td>
                    <button class="btn btn-sm btn-danger btn-icon" @click="deleteAttachment(attachment)">
                        <inline-svg src="/assets/icons/trash.svg" />
                    </button>
                </td>
            </tr>
            </tbody>
        </table>

    </div>

</template>

<script>
import StatisticsService from '../../../js/services/StatisticsService';
import LegalService from '../../../js/services/LegalService';
import moment from "moment";

export default {
    data() {
        return {
            ipc: null,
            typeOptions: null,
            attachment: {},
            showFileInput: true,
        };
    },
    computed: {
        ipcId() {
            return this.$route.params.id;
        },
        selectableTypes() {
            const selectables = [];

            for (const type of this.typeOptions) {
                if (type.not_for.includes(this.ipc.type)) {
                    continue;
                }

                if (type.is_single) {
                    if (! this.ipc.attachments.find(a => a.type === type.id)) {
                        selectables.push(type);
                    }
                } else {
                    selectables.push(type);
                }
            }

            return selectables;
        },
        nameLabel() {
            if (this.attachment.type === 'priority') {
                return 'Priority number';
            } else {
                return 'Short description';
            }
        },
        multiDocTypeSelected() {
            return this.attachment.type && ! this.typeOptions.find(t => t.id === this.attachment.type).is_single;
        },
    },
    watch: {
        async ipcId() {
            await this.loadIpc();
        },
    },
    async mounted() {
        this.resetForm();
        this.typeOptions = (await LegalService.getLegalMeta()).attachment_types;
        await this.loadIpc();
    },
    methods: {
        async loadIpc() {
            this.ipc = await StatisticsService.getIPC(this.ipcId);
        },
        resetForm() {
            this.attachment = {
                date: {},
            };
        },
        async upload() {
            try {
                const formData = new FormData();

                formData.append('attachments[0][type]', this.attachment.type);
                formData.append('attachments[0][file]', this.$refs.fileInput.files[0]);

                if (this.attachment.name) {
                    formData.append('attachments[0][name]', this.attachment.name);
                }

                if (this.showDateInput && this.attachment.date.startDate) {
                    formData.append(
                        'attachments[0][date]',
                        moment(this.attachment.date.startDate).format('YYYY-MM-DD')
                    );
                }

                this.ipc = await StatisticsService.uploadIpcAttachment(this.ipcId, formData);

                this.resetForm();

                // Workaround to reset the validation state of the file input
                this.$refs.fileInput.value = null;
                this.showFileInput = false;
                await this.$nextTick();
                this.showFileInput = true;
                await this.$nextTick();
                this.$refs.fileValProvider.reset();
            } catch (e) {
                this.displayFormErrorsOrThrow(e);
            }
        },
        async deleteAttachment(attachment) {
            await StatisticsService.deleteIpcAttachment(attachment.id);

            this.ipc.attachments.splice(
                this.ipc.attachments.indexOf(attachment),
                1
            );
        },
    },
};
</script>
