<template>
    <GChart type="AreaChart" :data="dataTable" :options="options">
    </GChart>
</template>

<script>
import config from '../../../../js/config';

export default {
    props: {
        data: {
            type: Array,
        },
        chartTitle: {
            type: String,
        },
    },
    computed: {
        options() {
            const options = {
                title: this.chartTitle,
                colors: [config.charts.colors['7f1']],
                width: '100%',
                height: 80,
                top: 0,
                chartArea: {
                    width: '100%',
                    height: 55
                },
                hAxis: {
                    baselineColor: 'none',
                    ticks: [],
                    title: ''
                },
                vAxis: {
                    baselineColor: 'none',
                    ticks: []
                }
            };

            return _.merge(options, config.charts.options);
        },
        dataTable() {
            const table = [];

            table.push(['Interval', '']);

            if (this.data) {
                for (const value of this.data) {
                    table.push([value.label, value.value || 0]);
                }
            }

            return table;
        },
    },
};
</script>
