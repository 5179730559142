<template>
    <div class="y-scrollable-block position-relative overflow-auto">
        <table class="table table-bordered table-has-sticky table-sticky-col table-layout-fixed">
            <thead>
            <tr>
                <th>Products</th>
                <th v-for="year in data.year_totals">
                    {{ year.year }}{{ year.year_status === 'future' ? ' (FC)' : '' }}
                </th>
            </tr>
            </thead>
            <tbody>
            <sales-evolution-table-row :data="data.year_totals">
                <th>Total</th>
            </sales-evolution-table-row>

            <template v-for="series in data.series">
                <sales-evolution-table-row :data="series.year_totals"
                                         :class="['collapsible-row', { 'collapsible-row-open': series.open }]">
                    <th @click="toggleCollapsed(series)">{{ series.series.name }}</th>
                </sales-evolution-table-row>

                <template v-if="series.open">
                    <sales-evolution-table-row v-for="product in series.products" :data="product.years"
                                             :key="product.product.id" class="collapsible-row-open">
                        <th class="font-weight-normal">{{ product.product.product_number }}</th>
                    </sales-evolution-table-row>
                </template>
            </template>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
th, td {
    width: 120px;
}
</style>

<script>
import SalesEvolutionTableRow from './SalesEvolutionTableRow';

export default {
    components: {
        SalesEvolutionTableRow,
    },
    props: {
        data: {
            required: true,
            type: Object,
        },
    },
    methods: {
        toggleCollapsed(series) {
            this.$set(series, 'open', ! series.open);
        },
    },
};
</script>
