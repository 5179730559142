<template>
    <div class="card-body">
        <h1 class="card-title mb-4">Log in</h1>

        <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
            <form class="form-horizontal" @submit.prevent="handleSubmit(login)">
                <ValidationProvider tag="div" class="form-group" name="email" rules="required|email" v-slot="{ errors }">
                    <input type="text" class="form-control" placeholder="E-mail" v-model="formData.email">
                    <div class="text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="password" rules="required" v-slot="{ errors }">
                    <input type="password" class="form-control" placeholder="Password" v-model="formData.password">
                    <div class="text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <div class="form-group remember-and-forgot">
                    <div>
                        <div class="custom-control custom-checkbox">
                            <input id="loginRemember"
                                   type="checkbox"
                                   class="custom-control-input"
                                   v-model="formData.remember">
                            <label for="loginRemember" class="custom-control-label">Remember me</label>
                        </div>
                    </div>
                    <div class="forgot">
                        <router-link :to="{ name: 'auth.forgot' }">
                            Forgot your password?
                        </router-link>
                    </div>
                </div>

                <div class="form-group mb-0">
                    <button type="submit" class="btn btn-primary btn-block">
                        Log in
                    </button>

                    <a href="https://www.sevenfriday.com/pages/get-in-touch" target="_blank" class="btn btn-block btn-link pb-0">
                        Need help?
                    </a>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<style scoped>
.remember-and-forgot .forgot {
    margin-top: 10px;
}

@media (min-width: 470px) {
    .remember-and-forgot {
        display: flex;
    }

    .remember-and-forgot .forgot {
        flex: 1;
        margin-top: 0;
        text-align: right;
    }
}
</style>

<script>
import AuthService from '../../../js/services/AuthService';
import config from '../../../js/config';

export default {
    data() {
        return {
            formData: {},
        };
    },
    methods: {
        async login() {
            try {
                await AuthService.login(
                    this.formData.email, this.formData.password, this.formData.remember
                );

                this.$router.replace(config.homeRoute);
            } catch (e) {
                if (e.response && e.response.data && e.response.data.error === 'invalid_grant') {
                    this.$refs.formValidator.setErrors({
                        email: 'Invalid credentials',
                    });
                } else {
                    throw e;
                }
            }
        },
    },
}
</script>
