<template>
    <div class="position-relative overflow-auto">
        <table class="table table-bordered table-has-sticky table-sticky-col table-layout-fixed cell-align-left">
            <colgroup>
                <col class="column-1">
                <col span="12" class="columns">
            </colgroup>
            <thead>
            <tr>
                <th></th>
                <th colspan="2">Ordered</th>
                <th colspan="2">Invoiced (open OC)</th>
                <th colspan="2">Delivered</th>
                <th colspan="2">Budget</th>
                <th colspan="2">Market Forecast</th>
                <th colspan="2">Delta</th>
            </tr>
            <tr>
                <th>Products</th>
                <template v-for="n in 6">
                    <th>QTY</th>
                    <th>Value</th>
                </template>
            </tr>
            </thead>
            <tbody>
            <sales-flash-table-row :data="data.total">
                <th>Total</th>
            </sales-flash-table-row>

            <sales-flash-table-row v-for="group in data.groups" :data="group.statistics" :key="group.group.id">
                <th>{{ group.group.name }}</th>
            </sales-flash-table-row>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
.column-1 {
    width: 133px;
}

.columns {
    width: 90px;
}
</style>

<script>
import SalesFlashTableRow from './SalesFlashTableRow';

export default {
    components: {
        SalesFlashTableRow
    },
    props: {
        data: {
            required: true,
            type: Object,
        },
    },
};
</script>
