<template>
    <div class="tile d-flex flex-column">
        <div class="mb-3">
            <img src="/assets/images/eiffel-watch.png" width="100%" height="170" />
        </div>

        <product-rank-table :data="slicedRanks" />

        <a href="#" @click.prevent="showModal">See full list</a>

        <b-modal id="product-rank-modal" scrollable ok-only>
            <div class="py-3 bg-white text-right">
                <a href="#" class="text-primary font-weight-bold"
                   @click="$emit('export')">Export to XLS</a>
            </div>
            <product-rank-table :data="data" />
        </b-modal>
    </div>
</template>

<style scoped>
a {
    text-align: right;
    font-weight: 700;
    color: #E31837;
    margin-top: 5px;
}
</style>

<script>
import ProductRankTable from './tables/ProductRankTable'

export default {
    components: {
        ProductRankTable
    },
    props: {
        data: {
            required: false,
            type: Array,
        }
    },
    computed: {
        slicedRanks() {
            if (!this.data) {
                return null;
            }

            return this.data.slice(0, 3);
        }
    },
    methods: {
        showModal() {
            this.$bvModal.show('product-rank-modal');
        }
    }
};
</script>
