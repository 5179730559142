<template>
    <GChart type="ColumnChart" :data="dataTable" :options="options">
    </GChart>
</template>

<script>
import config from '../../../js/config';

export default {
    props: {
        timeToMarketData: {
            required: true,
            type: Object,
        },
        compact: {
            required: false,
            type: Boolean,
            default: false,
        }
    },
    computed: {
        options() {
            const options = {
                colors: [
                    config.charts.colors.distributor1,
                    // config.charts.colors.retailer1,
                ],
                vAxis: {
                    title: 'Days',
                },
            };

            if (this.compact) {
                delete options.vAxis;

                options.chartArea = {
                    width: '85%',
                    height: '80%'
                };
            }

            return _.merge(options, config.charts.options);
        },
        dataTable() {
            const table = [[
                'Series',
                'Distributor',
                // 'Retailer',
            ]];

            for (const series of this.timeToMarketData.series) {
                table.push([
                    series.series.name,
                    series.total.stock_time_distributor || 0,
                    // series.total.retailer_ttm,
                ]);
            }

            return table;
        },
    },
};
</script>
