<template>
    <span v-if="stock === null">
        {{ null | dash }}
    </span>
    <span v-else-if="monthStock === -1">
        ∞
    </span>
    <span v-else-if="monthStock === null">
        No 7F forecast
    </span>
    <number-value v-else :n="monthStock" :percents="false" :thres="2.5" />
</template>

<script>
export default {
    props: {
        monthStock: {
            required: false,
            type: Number,
            default: null,
        },
        stock: {
            required: false,
            type: Number,
            default: null,
        }
    },
};
</script>
