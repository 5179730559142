<template>
    <div :class="['tile', tileClass]">
        <h3 class="mb-2">Key information</h3>

        <div class="d-flex flex-column">
            <div class="info-row">
                <span>Contacts</span>
            </div>

            <div class="info-row padded">
                <span>E-mail:</span>
                <span>{{ retailer.email }}</span>
            </div>

            <div class="info-row padded">
                <span>Mobile:</span>
                <span>{{ retailer.phoneNumber }}</span>
            </div>

            <div class="info-row">
                <span>Address</span>
            </div>

            <div class="info-row padded">
                <span>Invoicing:</span>
                <span>
                    {{ retailer.invoicingAddress.street }}
                    <br />
                    {{ retailer.invoicingAddress.city }} {{ retailer.invoicingAddress.country }}
                </span>
            </div>

            <div class="info-row padded">
                <span>Shipping:</span>
                <span>
                    {{ retailer.shippingAddress.street }}
                    <br />
                    {{ retailer.shippingAddress.city }} {{ retailer.invoicingAddress.country }}
                </span>
            </div>

            <div class="info-row">
                <span>Invoicing currency</span>
                <span>{{ retailer.invoicingCurrency }}</span>
            </div>

            <div class="info-row">
                <span>Shipping method</span>
                <span>{{ retailer.shippingMethod }}</span>
            </div>

            <div class="info-row">
                <span>Country(es)</span>
                <span>{{ retailer.countries.join(', ') }}</span>
            </div>

            <div class="info-row">
                <span>Start of partnership</span>
                <span>{{ retailer.partnershipStartDate }}</span>
            </div>

            <div class="info-row">
                <span>No of logins (last 3 mts)</span>
                <span>{{ retailer.numberOfLogins }}</span>
            </div>

            <div class="info-row">
                <span>Last login</span>
                <span>{{ retailer.lastLoginDate }}</span>
            </div>
        </div>            
    </div>
</template>

<style scoped>
.info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
}

.info-row.padded {
  margin-left: 15px;
}

.info-row:not(.padded) span:first-child {
  font-weight: 500;
}

.info-row span:last-child {
  text-align: end;
}
</style>

<script>
export default {
    props: {
        retailer: {
            required: true,
            type: Object,
        },
        tileClass: {
            required: false,
            type: String
        }
    }
};
</script>
