<template>
    <b-modal v-if="lawyer" id="lawyer-modal" :title="lawyer.name" scrollable hide-footer>
        <p v-if="lawyer.company">{{ lawyer.company }}</p>
        <p v-if="lawyer.address">{{ lawyer.address }}</p>
        <p v-if="lawyer.phone" class="font-weight-bold text-primary">{{ lawyer.phone }}</p>
        <p v-if="lawyer.email" class="font-weight-bold text-primary">{{ lawyer.email }}</p>
    </b-modal>
</template>

<script>
export default {
    props: {
        lawyer: {
            required: false,
            type: Object,
        },
    },
};
</script>
