<template>
    <tr>
        <slot></slot>
        <td v-if="! showOnly || showOnly === 'sales_7f_ws'">{{ data.sales_7f_ws_quantity | number }}</td>
        <td v-if="! showOnly || showOnly === 'sales_7f_ws'">{{ data.sales_7f_ws_price | price }}</td>
        <td v-if="! showOnly || showOnly === 'sales_7f_oc'">{{ data.sales_7f_oc_quantity | number }}</td>
        <td v-if="! showOnly || showOnly === 'sales_7f_oc'">{{ data.sales_7f_oc_price | price }}</td>
        <td v-if="! showOnly || showOnly === 'sales_7f_in'">{{ data.sales_7f_in_quantity | number }}</td>
        <td v-if="! showOnly || showOnly === 'sales_7f_in'">{{ data.sales_7f_in_price | price }}</td>
        <td v-if="! showOnly || showOnly === 'budget'">{{ data.budget_quantity | number }}</td>
        <td v-if="! showOnly || showOnly === 'budget'">{{ data.budget_price | price }}</td>
        <td v-if="! showOnly || showOnly === 'forecasts'">{{ data.forecasts_quantity | number }}</td>
        <td v-if="! showOnly || showOnly === 'forecasts'">{{ data.forecasts_price | price }}</td>
        <td v-if="! showOnly || showOnly === 'delta'"><number-value :n="data.delta_quantity" percents></number-value></td>
        <td v-if="! showOnly || showOnly === 'delta'"><number-value :n="data.delta_price" percents></number-value></td>
    </tr>
</template>

<script>
export default {
    props: {
        data: {
            required: true,
            type: Object,
        },
        showOnly: {
            required: false,
            type: String,
        },
    },
};
</script>
