<template>
    <sweet-modal ref="modal"
                 hide-close-button
                 :enable-mobile-fullscreen="false"
                 :icon="icon">
        <slot>{{ message }}</slot>
        <template v-slot:button>
            <button class="btn btn-primary" @click="$_handleOk">
                {{ okButtonLabel }}
            </button>
            <button v-if="confirm" class="btn btn-secondary" @click="$_handleCancel">
                {{ cancelButtonLabel }}
            </button>
        </template>
    </sweet-modal>
</template>

<script>
export default {
    props: {
        message: {
            required: false,
            type: String,
        },
        icon: {
            required: true,
            type: String,
        },
        okButtonLabel: {
            required: false,
            type: String,
            default: 'OK',
        },
        cancelButtonLabel: {
            required: false,
            type: String,
            default: 'Cancel',
        },
        confirm: {
            required: false,
            type: Boolean,
            default: false,
        },
        closeOnOk: {
            required: false,
            type: Boolean,
            default: true,
        },
        closeOnCancel: {
            required: false,
            type: Boolean,
            default: true,
        },
    },
    methods: {
        open() {
            this.$refs.modal.open();
        },
        close() {
            this.$refs.modal.close();
        },
        $_handleOk() {
            this.$emit('ok');

            if (this.closeOnOk) {
                this.close();
            }
        },
        $_handleCancel() {
            this.$emit('cancel');

            if (this.closeOnCancel) {
                this.close();
            }
        }
    },
};
</script>
