<template>
    <table class="table table-bordered table-has-sticky table-sticky-row table-layout-fixed" v-if="data">
        <thead>
        <tr>
            <th>Rank</th>
            <th>Country</th>
            <th>NR of reg</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(record, idx) in data">
            <td>{{ idx + 1 }}</td>
            <td style="word-break: break-all;">{{ record.country }}</td>
            <td>{{ record.count | number }}</td>
        </tr>
        </tbody>
    </table>
    <b-skeleton-table v-else :rows="3" :columns="3"
                      :table-props="{ bordered: true }" />
</template>

<style scoped>
.table {
    margin-top: 0;
    margin-bottom: 0;
}
</style>

<script>
export default {
    props: {
        data: {
            required: false,
            type: Array,
        },
    },
};
</script>
