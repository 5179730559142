import AuthService from './services/AuthService';
import SpinnerService from './services/SpinnerService';

window.axios.defaults.baseURL = process.env.MIX_API_URL;

// Access token

window.axios.interceptors.request.use(
    request => {
        const accessToken = AuthService.getAccessToken();

        if (accessToken) {
            request.headers.Authorization = `Bearer ${AuthService.getAccessToken()}`;
        }

        return request;
    },
    null
);

// Spinner and general error handling

window.axios.interceptors.request.use(
    request => {
        if (request.__hideSpinner !== true) {
            SpinnerService.work();
        }

        return request;
    },
    error => {
        if (error.request.__hideSpinner !== true) {
            SpinnerService.done();
        }

        return Promise.reject(error);
    }
);

window.axios.interceptors.response.use(
    response => {
        if (response.request.__hideSpinner !== true) {
            SpinnerService.done();
        }

        return response;
    },
    error => {
        if (error.request.__hideSpinner !== true) {
            SpinnerService.done();
        }

        return Promise.reject(error);
    }
);
