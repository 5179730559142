<template>
    <div>
        <template v-if="selectedPlan === 'master'">
            <h2>Master purchase plan</h2>
        </template>
        <template v-else-if="selectedPlan === 'product'">
            <h2>Product purchase plan</h2>
        </template>

        <div class="d-flex justify-content-between align-items-center">
            <b-form-radio-group v-model="selectedPlan"
                                :options="planSelectorOptions"
                                buttons button-variant="light"
                                class="btn-group-separate">
            </b-form-radio-group>

            <a href="#" class="text-primary font-weight-bold"
               @click="exportSelectedPlan">Export to XLS</a>
        </div>

        <template v-if="selectedPlan === 'master'">
            <filter-set :filters="['dateRange', 'productGroup']" :only-forecastable="true" key="master"
                        v-model="masterPlanFilters" @filter-change="handleFilterChange('Master', $event)">
            </filter-set>

            <master-purchase-plan-table v-if="masterPurchasePlanData" :data="masterPurchasePlanData">
            </master-purchase-plan-table>
        </template>
        <template v-else-if="selectedPlan === 'product'">
            <filter-set :filters="['search', 'dateRange', 'productGroup', 'productSeries']" :only-forecastable="true" key="product"
                        v-model="productPlanFilters" @filter-change="handleFilterChange('Product', $event)">
                <div class="col-auto mb-2">
                    <v-select style="min-width: 200px;"
                              v-model="productPlanFilters.has_7f_forecasts"
                              @input="handleFilterChange('Product', {filter: 'has_7f_forecasts'})"
                              :options="[{id: '1', label: 'Active'}, {id: '0', label: 'Inactive'}]"
                              :clearable="false"
                              :reduce="opt => opt.id"
                              :searchable="false">
                    </v-select>
                </div>
            </filter-set>

            <template v-if="productPurchasePlanData" v-for="product in filteredProductPurchasePlanData">
                <product-purchase-plan-tables :product="product"
                                              :can-edit7f-forecast="currentUser.can.includes('edit-7f-forecast')"
                                              @update-7f-forecast="update7fForecast">
                </product-purchase-plan-tables>
            </template>
        </template>
    </div>
</template>

<script>
import StatisticsService from '../../js/services/StatisticsService';
import MasterPurchasePlanTable from '../components/purchase-plan/MasterPurchasePlanTable';
import ProductPurchasePlanTables from '../components/purchase-plan/ProductPurchasePlanTables';
import CurrentUserService from '../../js/services/CurrentUserService';
import SevenfridayForecastService from '../../js/services/SevenfridayForecastService';
import {downloadBlobResponse} from '../../js/helpers';

export default {
    components: {
        MasterPurchasePlanTable,
        ProductPurchasePlanTables,
    },
    data() {
        return {
            currentUser: null,
            selectedPlan: 'master',
            planSelectorOptions: [
                { text: 'Master purchase plan', value: 'master' },
                { text: 'Product purchase plan', value: 'product' },
            ],
            masterPurchasePlanData: null,
            productPurchasePlanData: null,
            masterPlanFilters: {
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
                productGroup: 1,
            },
            productPlanFilters: {
                search: null,
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
                productGroup: 1,
                productSeries: null,
                has_7f_forecasts: '1',
            },
        };
    },
    computed: {
        filteredProductPurchasePlanData() {
            if (! this.productPlanFilters.search) {
                return this.productPurchasePlanData;
            }

            return this.productPurchasePlanData.filter(
                p => p.product.product_number.toLowerCase().includes(this.productPlanFilters.search.toLowerCase())
            );
        },
    },
    watch: {
        async selectedPlan() {
            if (this.selectedPlan === 'master' && ! this.masterPurchasePlanData) {
                await this.loadMasterPurchasePlanData();
            }

            if (this.selectedPlan === 'product' && ! this.productPurchasePlanData) {
                await this.loadProductPurchasePlanData();
            }
        },
    },
    async mounted() {
        const result = await Promise.all([
            CurrentUserService.get(),
            this.loadMasterPurchasePlanData(),
        ]);

        this.currentUser = result[0];
    },
    methods: {
        async loadMasterPurchasePlanData() {
            this.masterPurchasePlanData = (await StatisticsService.getMasterPurchasePlan(
                this.masterPlanFilters
            )).months;
        },
        async loadProductPurchasePlanData() {
            this.productPurchasePlanData = (await StatisticsService.getProductPurchasePlan(
                this.productPlanFilters
            )).products;
        },
        async handleFilterChange(plan, change) {
            if (change.filter === 'search') {
                return;
            }

            await this[`load${plan}PurchasePlanData`]();
        },
        async exportSelectedPlan() {
            downloadBlobResponse(
                await StatisticsService['get' + _.startCase(this.selectedPlan) + 'PurchasePlan'](
                    this[this.selectedPlan + 'PlanFilters'], true
                ),
                this.selectedPlan + '_purchase_plan.xlsx'
            );
        },
        async update7fForecast(data) {
            await SevenfridayForecastService.update(data);
            await this.loadProductPurchasePlanData();
        },
    },
};
</script>
