<template>
    <table class="table table-bordered table-has-sticky table-sticky-row table-layout-fixed">
        <thead>
        <tr>
            <th>Products</th>
            <th>Stock</th>
            <th>Month stock</th>
            <th>Missing QTY</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="product in data">
            <td>{{ product.product.product_number }}</td>
            <td>{{ product.statistics.stock_7f | number }}</td>
            <td>
                <month-stock-value :month-stock="product.statistics.month_stock"
                                   :stock="product.statistics.stock_7f" />
            </td>
            <td>{{ product.statistics.missing_quantity | number }}</td>
        </tr>
        </tbody>
    </table>
</template>

<style scoped>
.table {
    margin-top: 0;
}
</style>

<script>
import MonthStockValue from './MonthStockValue';

export default {
    components: {
        MonthStockValue,
    },
    props: {
        data: {
            required: true,
            type: Array,
        },
    },
};
</script>
