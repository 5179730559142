<template>
    <GChart type="ColumnChart" :data="dataTable" :options="options">
    </GChart>
</template>

<script>
import config from '../../../js/config';

export default {
    props: {
        data: {
            required: true,
            type: Object,
        },
        show: {
            required: false,
            type: String,
            default: 'all',
        },
        compact: {
            required: false,
            type: Boolean,
            default: false,
        }
    },
    computed: {
        sliceIdx() {
            if (this.show === '7f') {
                return { start: 0, end: 1 };
            } else if (this.show === 'distributor') {
                return { start: 1, end: 2 };
            } else if (this.show === 'retailer') {
                return { start: 2, end: 3 };
            } else {
                return { start: 1, end: 3 };
            }
        },
        options() {
            const options = {
                colors: [
                    config.charts.colors['7f2'],
                    config.charts.colors.distributor2,
                    config.charts.colors.retailer2,
                ].slice(this.sliceIdx.start, this.sliceIdx.end),
                vAxis: {
                    title: 'Quantity',
                },
                width: '100%',
                height: 300,
            };

            if (this.compact) {
                options.chartArea = {
                    width: '85%',
                    height: '80%',
                };
            }

            return _.merge(options, config.charts.options);
        },
        dataTable() {
            const table = [];

            const headers = [
                '7F sales / FC',
                'Distributor sales',
                // 'Retailer sales',
            ].slice(this.sliceIdx.start, this.sliceIdx.end);

            headers.unshift('Year');

            table.push(headers);

            for (const year of this.data.year_totals) {
                const row = [
                    (year.year_status === 'future' ? year.forecasts_quantity : year.sales_7f_quantity) || 0,
                    year.sales_distributor_quantity || 0,
                    // year.sales_retailer_quantity || 0,
                ].slice(this.sliceIdx.start, this.sliceIdx.end);

                row.unshift(year.year.toString() + (year.year_status === 'future' ? ' (FC)' : ''));

                table.push(row);
            }

            return table;
        },
    },
};
</script>
