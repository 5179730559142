<template>
    <div class="y-scrollable-block-layout">
        <h2>Lawyers</h2>

        <div class="d-flex justify-content-between align-items-end mb-1">
            <div></div>

            <div>
                <a href="#" class="text-primary font-weight-bold mr-4" v-if="canManage"
                   @click="$router.push({name: 'legal.lawyer.create'})">Add lawyer</a>
            </div>
        </div>

        <lawyers-table v-if="data" :data="data" :show-actions="canManage"
            @sort="handleSorting" @delete="confirmDeleteLawyer" />

        <simple-modal ref="deleteLawyerConfirmationModal" icon="warning" confirm
                      @ok="deleteLawyer(deletableLawyer)" v-if="deletableLawyer">
            Are you sure you want to permanently delete {{ deletableLawyer.name }}?
        </simple-modal>
    </div>
</template>

<script>
import CurrentUserService from '../../../js/services/CurrentUserService';
import LawyersTable from '../../components/legal/LawyersTable';
import StatisticsService from '../../../js/services/StatisticsService';
import UserService from '../../../js/services/UserService';

export default {
    components: {
        LawyersTable,
    },
    data() {
        return {
            data: null,
            canManage: false,
            sorting: { col: 'name', dir: 'asc' },
            deletableLawyer: null,
        };
    },
    async mounted() {
        this.canManage = (await CurrentUserService.get()).can.includes('manage-ipcs');
        await this.loadLawyers();
    },
    methods: {
        async loadLawyers() {
            this.data = await StatisticsService.indexLawyers(this.sorting);
        },
        async handleSorting(sorting) {
            this.sorting = sorting;
            await this.loadLawyers();
        },
        async confirmDeleteLawyer(lawyer) {
            this.deletableLawyer = lawyer;
            await this.$nextTick();
            this.$refs.deleteLawyerConfirmationModal.open();
        },
        async deleteLawyer(lawyer) {
            await StatisticsService.deleteLawyer(lawyer.id);

            this.data.splice(
                this.data.indexOf(lawyer), 1
            );
        },
    },
};
</script>
