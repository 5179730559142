<template>
    <tr>
        <slot></slot>
        <td v-for="year in data">
            <template v-if="year.year_status === 'future'">
                {{ year.forecasts_quantity | number }}
            </template>
            <template v-else>
                {{ year.sales_7f_quantity | number }}
            </template>
        </td>
    </tr>
</template>

<script>
export default {
    props: {
        data: {
            required: true,
            type: Array,
        },
    },
};
</script>
