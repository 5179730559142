<template>
    <tr>
        <slot></slot>
        <td>{{ data.avg_sales_7f_quantity | number }}</td>
        <td>{{ data.stock_7f | number }}</td>
    </tr>
</template>

<script>
export default {
    props: {
        data: {
            required: true,
            type: Object,
        },
    },
};
</script>
