<template>
    <div class="row align-items-center">
        <div v-if="filters.includes('search')" class="col-auto mb-2">
            <search-input :value="value.search"
                          style="max-width: 270px;"
                          @input="emitUpdatedFilters('search', $event)">
            </search-input>
        </div>

        <div v-if="filters.includes('date')" class="col-auto mb-2">
            <date-range-picker :value="value.date" :range="false"
                               style="min-width: 170px;" :clearable="false"
                               @input="emitUpdatedFilters('date', $event)"
                               :opens="datePickerOpens">
            </date-range-picker>
        </div>

        <div v-if="filters.includes('dateRange')" class="col-auto mb-2">
            <date-range-picker :value="value.dateRange" :range="true"
                               style="min-width: 270px;"
                               @input="emitUpdatedFilters('dateRange', $event)"
                               :opens="datePickerOpens">
            </date-range-picker>
        </div>

        <div v-if="filters.includes('productGroup') && productGroupOptions" class="col-auto mb-2">
            <v-select style="min-width: 235px;"
                      :value="value.productGroup"
                      @input="emitUpdatedFilters('productGroup', $event)"
                      :options="filteredGroupOptions"
                      :clearable="! onlyForecastable && ! onlyWithDeviceNumbers"
                      label="name"
                      :reduce="productGroup => productGroup.id"
                      placeholder="Select product category"
                      :searchable="false">
            </v-select>
        </div>

        <div v-if="filters.includes('productSeries')" class="col-auto mb-2">
            <v-select style="min-width: 190px;"
                      v-model="selSeriesId"
                      :options="filteredSeriesOptions"
                      :get-option-label="generateSeriesLabel"
                      :reduce="series => series.id"
                      placeholder="Select series"
                      :searchable="false">
            </v-select>
        </div>

        <div v-if="filters.includes('product')" class="col-auto mb-2">
            <v-select style="min-width: 190px;"
                      v-model="selProductId"
                      :options="filteredProductOptions"
                      label="product_number"
                      :reduce="product => product.id"
                      placeholder="Select product"
                      :searchable="true">
            </v-select>
        </div>

        <div v-if="filters.includes('others')" class="col-auto mb-2">
            <button class="btn btn-link btn-link-regular text-danger font-weight-bold" @click="$bvModal.show('filterModal')">
                <inline-svg src="/assets/icons/plus.svg">
                </inline-svg>
                {{ filterButtonLabel }}
            </button>
        </div>

        <div v-if="filters.includes('b2c')" class="col-auto mb-2">
            <button class="btn btn-link btn-link-regular text-danger font-weight-bold" @click="$bvModal.show('b2cFilterModal')">
                <inline-svg src="/assets/icons/plus.svg">
                </inline-svg>
                {{ filterButtonLabel }}
            </button>
        </div>

        <div v-if="filters.includes('legal')" class="col-auto mb-2">
            <button class="btn btn-link btn-link-regular text-danger font-weight-bold" @click="$bvModal.show('legalFilterModal')">
                <inline-svg src="/assets/icons/plus.svg">
                </inline-svg>
                {{ filterButtonLabel }}
            </button>
        </div>

        <slot></slot>

        <b-modal v-if="filters.includes('others')" id="filterModal" title="Add filter" centered hide-footer>
            <form class="form-horizontal" @submit.prevent="handleFilterModalSubmit">
                <div class="form-group" v-if="continentOptions">
                    <v-select v-model="selContinentId"
                              :options="continentOptions"
                              label="name"
                              :reduce="continent => continent.id"
                              placeholder="Filter by region"
                              :searchable="false">
                    </v-select>
                </div>

                <div class="form-group" v-if="countryOptions">
                    <v-select v-model="selCountryId"
                              :options="filteredCountryOptions"
                              label="name"
                              :reduce="country => country.id"
                              placeholder="Filter by country"
                              :searchable="true">
                    </v-select>
                </div>

                <div class="form-group" v-if="distributorOptions">
                    <v-select v-model="selDistributorId"
                              :options="filteredDistributorOptions"
                              label="name"
                              :reduce="distributor => distributor.id"
                              placeholder="Filter by distributor"
                              :searchable="true">
                    </v-select>
                </div>

                <div class="form-group mb-0">
                    <button type="submit" class="btn btn-primary btn-block">
                        OK
                    </button>
                </div>
            </form>
        </b-modal>

        <b-modal v-if="filters.includes('b2c')" id="b2cFilterModal" title="Add filter" centered hide-footer>
            <form class="form-horizontal" @submit.prevent="handleB2cFilterModalSubmit">
                <div class="form-group" v-if="countryOptions">
                    <v-select v-model="selCountryId"
                              :options="countryOptions"
                              label="name"
                              :reduce="country => country.id"
                              placeholder="Filter by country"
                              :searchable="true">
                    </v-select>
                </div>

                <div class="form-group">
                    <v-select v-model="city"
                              :options="b2cCityOptions"
                              placeholder="Filter by city"
                              :searchable="true">
                    </v-select>
                </div>

                <div class="form-group">
                    <v-select v-model="selProductCategoryId"
                              :options="productCategories"
                              label="name"
                              :reduce="cat => cat.id"
                              placeholder="Filter by product category"
                              :multiple="true"
                              :searchable="false">
                    </v-select>
                </div>

                <div class="form-group">
                    <v-select v-model="selNumberOfProductsId"
                              :options="numberOfProducts"
                              label="name"
                              :reduce="nr => nr.id"
                              placeholder="Filter by number of owned products"
                              :searchable="false">
                    </v-select>
                </div>

                <div class="form-group mb-0">
                    <button type="submit" class="btn btn-primary btn-block">
                        OK
                    </button>
                </div>
            </form>
        </b-modal>

        <b-modal v-if="filters.includes('legal')" id="legalFilterModal" title="Add filter" centered hide-footer>
            <form class="form-horizontal" @submit.prevent="handleLegalFilterModalSubmit">
                <div class="form-group" v-if="countryOptions">
                    <v-select v-model="selCountryId"
                              :options="countryOptions"
                              label="name"
                              :reduce="country => country.id"
                              placeholder="Filter by country"
                              :searchable="true">
                    </v-select>
                </div>

                <div class="form-group">
                    <v-select v-model="selIpcClass"
                              :options="ipcClasses"
                              placeholder="Filter by class"
                              :searchable="false">
                    </v-select>
                </div>

                <div class="form-group">
                    <input class="form-control"
                           v-model="ipcCategory"
                           placeholder="Search in categories" />
                </div>

                <div class="form-group">
                    <input class="form-control"
                           v-model="ipcSeries"
                           placeholder="Search in series" />
                </div>

                <div class="form-group mb-0">
                    <button type="submit" class="btn btn-primary btn-block">
                        OK
                    </button>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
// @todo when eg. a product is selected, then an unmatching product group gets selected, don't emit twice
// @todo nicer responsive layout
// @todo refact

import ProductCategorizationService from '../../js/services/ProductCategorizationService';
import ProductService from '../../js/services/ProductService';
import GeoService from '../../js/services/GeoService';
import DistributorService from '../../js/services/DistributorService';
import LegalService from '../../js/services/LegalService';

export default {
    props: {
        value: {
            required: true,
            type: Object,
        },
        // Possible: search, date, dateRange, productGroup, productSeries, product, others, b2c, legal
        filters: {
            required: true,
            type: Array,
        },
        productOptions: {
            required: false,
            type: Array,
            default: null,
        },
        seriesOptions: {
            required: false,
            type: Array,
            default: null,
        },
        onlyForecastable: {
            required: false,
            type: Boolean,
            default: false,
        },
        onlyWithDeviceNumbers: {
            required: false,
            type: Boolean,
            default: false,
        },
        datePickerOpens: {
            required: false,
            type: String,
        }
    },
    data() {
        return {
            productGroupOptions: null,
            continentOptions: null,
            countryOptions: null,
            b2cCityOptions: null,
            distributorOptions: null,
            selSeriesIdBuf: null,
            selProductIdBuf: null,
            selContinentId: null,
            selCountryIdBuf: null,
            selDistributorIdBuf: null,
            allProductSeries: null,
            allProducts: null,

            city: null,
            selProductCategoryId: null,
            selNumberOfProductsId: null,

            selIpcClass: null,
            ipcCategory: null,
            ipcSeries: null,

            productCategories: [
                { id: 'watch', name: 'Watch' },
                { id: 'eyewear', name: 'Eyewear' },
                { id: 'accessories', name: 'Accessories' },
            ],

            numberOfProducts: [
                { id: 0, name: '0'},
                { id: 1, name: '1'},
                { id: 2, name: '2'},
                { id: 3, name: '3'},
                { id: 4, name: '4'},
                { id: 5, name: '5'},
                { id: 6, name: '6'},
                { id: 7, name: '7'},
                { id: 8, name: '8'},
                { id: 9, name: '9'},
                { id: 10, name: '10 or more'},
            ],

            ipcClasses: null,
        };
    },
    computed: {
        filterButtonLabel() {
            return this.value.continent || this.value.country || this.value.distributor || this.value.number_of_products || this.value.city || this.value.product_category || this.value.category || this.value.class || this.value.series
                ? 'Edit filters'
                : 'Add filter';
        },
        filteredGroupOptions() {
            return this.productGroupOptions.filter(
                g => (! this.onlyForecastable || g.is_forecastable)
                    && (! this.onlyWithDeviceNumbers || g.has_device_numbers)
            );
        },
        filteredSeriesOptions() {
            let seriesOptions;

            if (this.seriesOptions) {
                seriesOptions = this.seriesOptions;
            } else if (this.allProductSeries) {
                seriesOptions = this.allProductSeries;
            } else {
                seriesOptions = [];
            }

            if (this.value.productGroup) {
                return seriesOptions.filter(s => s.product_group_id === this.value.productGroup);
            } else {
                return seriesOptions;
            }
        },
        filteredProductOptions() {
            let productOptions;

            if (this.productOptions) {
                productOptions = this.productOptions;
            } else if (this.allProducts) {
                productOptions = this.allProducts;
            } else {
                productOptions = [];
            }

            if (this.value.productSeries) {
                return productOptions.filter(p => p.product_series_id === this.value.productSeries);
            } else if (this.value.productGroup) {
                return productOptions.filter(p => p.product_group_id === this.value.productGroup);
            } else {
                return productOptions;
            }
        },
        filteredCountryOptions() {
            if (! this.selContinentId) {
                return this.countryOptions;
            } else {
                return this.countryOptions.filter(
                    c => c.continent_id === this.selContinentId
                );
            }
        },
        filteredDistributorOptions() {
            if (this.selCountryId) {
                return this.distributorOptions.filter(
                    d => d.country_id === this.selCountryId
                );
            } else if (this.selContinentId) {
                return this.distributorOptions.filter(
                    d => d.continent_id === this.selContinentId
                );
            } else {
                return this.distributorOptions;
            }
        },
        selSeriesId: {
            get() {
                return this.filteredSeriesOptions.find(s => s.id === this.selSeriesIdBuf)
                    ? this.selSeriesIdBuf
                    : null;
            },
            set(value) {
                this.selSeriesIdBuf = value;
            }
        },
        selProductId: {
            get() {
                return this.filteredProductOptions.find(p => p.id === this.selProductIdBuf)
                    ? this.selProductIdBuf
                    : null;
            },
            set(value) {
                this.selProductIdBuf = value;
            }
        },
        selCountryId: {
            get() {
                return this.filteredCountryOptions.find(c => c.id === this.selCountryIdBuf)
                    ? this.selCountryIdBuf
                    : null;
            },
            set(value) {
                this.selCountryIdBuf = value;
            }
        },
        selDistributorId: {
            get() {
                return this.filteredDistributorOptions.find(d => d.id === this.selDistributorIdBuf)
                    ? this.selDistributorIdBuf
                    : null;
            },
            set(value) {
                this.selDistributorIdBuf = value;
            }
        },
    },
    watch: {
        'value.productSeries'() {
            this.selSeriesIdBuf = this.value.productSeries;
        },
        'value.product'() {
            this.selProductIdBuf = this.value.product;
        },
        'value.continent'() {
            this.selContinentId = this.value.continent;
        },
        'value.country'() {
            this.selCountryIdBuf = this.value.country;
        },
        'value.distributor'() {
            this.selDistributorIdBuf = this.value.distributor;
        },
        selSeriesId() {
            this.emitUpdatedFilters('productSeries', this.selSeriesId)
        },
        selProductId() {
            this.emitUpdatedFilters('product', this.selProductId)
        },
        // seriesOptions() {
        //     if (! this.seriesOptions.find(s => s.id === this.value.productSeries)) {
        //         this.emitUpdatedFilters('productSeries', null);
        //     }
        // },
        // productOptions() {
        //     if (! this.productOptions.find(p => p.id === this.value.product)) {
        //         this.emitUpdatedFilters('product', null);
        //     }
        // },
    },
    async mounted() {
        const promises = [];

        if (this.filters.includes('productGroup') || this.filters.includes('productSeries')) {
            promises.push(this.loadProductGroupOptions());
        }

        if (this.filters.includes('productSeries') && ! this.seriesOptions) {
            promises.push(this.loadAllProductSeries());
        }

        if (this.filters.includes('product') && ! this.productOptions) {
            promises.push(this.loadAllProducts());
        }

        if (this.filters.includes('others')) {
            promises.push(this.loadOthersOptions());
        }

        if (this.filters.includes('b2c')) {
            promises.push(this.loadAllCountries());
            promises.push(this.loadAllCities());
        }

        if (this.filters.includes('legal')) {
            promises.push(this.loadAllCountries());
            promises.push(this.loadLegalMeta());
        }

        await Promise.all(promises);
    },
    methods: {
        async loadProductGroupOptions() {
            this.productGroupOptions = await ProductCategorizationService.getGroups();
        },
        async loadAllProductSeries() {
            this.allProductSeries = await ProductCategorizationService.getSeries();
        },
        async loadAllProducts() {
            this.allProducts = await ProductService.getAllProducts();
        },
        async loadAllCountries() {
            this.countryOptions = await GeoService.getCountries();
        },
        async loadAllCities() {
            this.b2cCityOptions = await GeoService.getB2cCities();
        },
        async loadLegalMeta() {
            const meta = await LegalService.getLegalMeta();

            this.ipcClasses = meta.classes;
        },
        async loadOthersOptions() {
            const responses = await Promise.all([
                GeoService.getContinents(),
                GeoService.getCountries(),
                DistributorService.getAllDistributors(),
            ]);

            this.continentOptions = responses[0];
            this.countryOptions = responses[1];
            this.distributorOptions = responses[2];
        },
        emitUpdatedFilters(updatedFilter, updatedValue) {
            const updated = Object.assign({}, this.value);

            if (updatedFilter === 'others') {
                updated.continent = this.selContinentId;
                updated.country = this.selCountryId;
                updated.distributor = this.selDistributorId;
            } else if (updatedFilter === 'b2c') {
                updated.country = this.selCountryId;
                updated.city = this.city;
                updated.product_category = this.selProductCategoryId;
                updated.number_of_products = this.selNumberOfProductsId;
            } else if (updatedFilter === 'legal') {
                updated.country = this.selCountryId;
                updated.class = this.selIpcClass;
                updated.category = this.ipcCategory;
                updated.series = this.ipcSeries;
            } else {
                updated[updatedFilter] = updatedValue;
            }

            this.$emit('input', updated);
            this.$emit('filter-change', { filter: updatedFilter });
        },
        handleFilterModalSubmit() {
            this.emitUpdatedFilters('others');
            this.$bvModal.hide('filterModal');
        },
        handleB2cFilterModalSubmit() {
            this.emitUpdatedFilters('b2c');
            this.$bvModal.hide('b2cFilterModal');
        },
        handleLegalFilterModalSubmit() {
            this.emitUpdatedFilters('legal');
            this.$bvModal.hide('legalFilterModal');
        },
        generateSeriesLabel(series) {
            if (this.value.productGroup) {
                return series.name;
            } else {
                const group = this.productGroupOptions.find(g => g.id === series.product_group_id);

                if (group) {
                    return series.name + ' (' + group.name + ')';
                } else {
                    return series.name;
                }
            }
        },
    },
};
</script>
