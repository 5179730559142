<template>
    <span :class="spanClass">{{ spanContent }}</span>
</template>

<script>
import {formatNumber, formatPercents} from '../../js/helpers';

export default {
    props: {
        n: {
            required: false,
            type: Number,
            default: null,
        },
        thres: {
            required: false,
            type: Number,
            default: 0,
        },
        percents: {
            required: false,
            type: Boolean,
            default: true,
        },
    },
    computed: {
        spanClass() {
            if (this.thres === null || this.n === null || isNaN(this.n)) {
                return '';
            } else if (this.n <= this.thres) {
                return 'text-danger';
            } else {
                return 'text-success';
            }
        },
        spanContent() {
            if (this.percents) {
                return formatPercents(this.n);
            } else {
                return formatNumber(this.n);
            }
        },
    },
};
</script>
