<template>
    <div :class="['tile d-flex flex-column', tileClass]">
        <h3 class="mb-3">Reports</h3>

        <b-form-radio-group
            v-model="selectedReportType"
            :options="reportTypes"
            buttons button-variant="light"
            class="btn-group-separate btn-group-wide mb-1" />

        <div class="mb-2">
            <filter-set
                v-model="selectedFilters"
                :filters="['dateRange', 'productGroup', 'product']"
                :product-options="products" />
        </div>

        <div class="mb-3">
            <template v-if="selectedReportType === 'salesFlash'">
                <sales-flash-chart :data="retailer.reports.flash.groups" />
            </template>

            <template v-if="selectedReportType === 'salesEvolution'">
                <sales-evolution-chart :data="salesEvolutionChartData" />
            </template>
        </div>

        <div class="d-flex justify-content-end mb-1">
            <export-button />
        </div>

        <div class="flex-grow-1">
            <template v-if="selectedReportType === 'salesFlash'">
                <sales-flash-table :data="retailer.reports.flash" />
            </template>

            <template v-if="selectedReportType === 'salesEvolution'">
                <sales-evolution-table
                    :columns="salesEvolutionColumns"
                    :data="retailer.reports.evolution" />
            </template>
        </div>
    </div>
</template>

<script>
import SalesFlashChart from './charts/SalesFlashChart'
import SalesEvolutionChart from './charts/SalesEvolutionChart'
import SalesFlashTable from './tables/SalesFlashTable'
import SalesEvolutionTable from './tables/SalesEvolutionTable'

export default {
    components: {
        SalesFlashChart,
        SalesEvolutionChart,
        SalesFlashTable,
        SalesEvolutionTable
    },
    props: {
        retailer: {
            required: true,
            type: Object,
        },
        tileClass: {
            required: false,
            type: String
        }
    },
    data() {
        return {
            products: [],

            selectedReportType: 'salesFlash',
            reportTypes: [
                { value: 'salesFlash', text: 'Sales flash'},
                { value: 'salesEvolution', text: 'Sales evolution' }
            ],

            selectedFilters: {
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
                productGroup: null,
                product: null,
            },

            salesEvolutionColumns: ['2016', '2017', '2018', '2019', '2020', '2021 FC', '2022 FC']
        };
    },
    computed: {
        salesEvolutionChartData() {
            if (!this.retailer.reports.evolution) {
                return [];
            }

            const data = this.salesEvolutionColumns.map(c => ({ name: c, sales: 0 }));

            for (const record of this.retailer.reports.evolution.groups) {
                for (const column of this.salesEvolutionColumns) {
                    const dataRecord = data.find(d => d.name === column);
                    dataRecord.sales += record[column];
                }
            }

            return data;
        },
    },
    watch: {
        selectedReportType() {
            console.log('new reporttype: ', this.selectedReportType)
        },
        selectedFilters() {
            console.log('new filters: ', this.selectedFilters)
        }
    },
    mounted() {
        this.loadProductList();
    },
    methods: {
        loadProductList() {
            this.products = [
                { id: 1, product_number: 'SF-M01/01' },
                { id: 2, product_number: 'SF-M02/02' },
            ];
        },
    },
};
</script>
