export default new class {
    constructor() {
        this.$_products = null;
    }

    getAllProducts() {
        if (! this.$_products) {
            this.$_products = axios.get('products', { params: { with_group_id: 1 } })
                .then(response => response.data.products);
        }

        return this.$_products;
    }
};
