<template>
    <div class="row mb-2">
        <div class="col-2">{{ data.element }}</div>
        <div class="col-2">{{ data.product }}</div>
        <div class="col-2">{{ data.action }}</div>
        <div class="col-2">{{ data.amount }}</div>
        <div class="col-2">
            <b-form-checkbox v-model="data.inAppNotification" />
        </div>
        <div class="col-2">
            <b-form-checkbox v-model="data.emailNotification" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            required: true,
            type: Object
        }
    }
}
</script>