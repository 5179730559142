<template>
    <div class="input-group input-group-filter-style">
        <div class="input-group-prepend ">
            <span class="input-group-text">
                <inline-svg src="/assets/icons/magnifier.svg">
                </inline-svg>
            </span>
        </div>
        <input type="text" class="form-control"
               placeholder="Search" aria-label="Search"
               v-model="searchTextBuf">
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: false,
            type: String,
            default: null,
        },
    },
    data() {
        return {
            searchTextBuf: this.value === null ? '' : this.value,
            timer: null,
        };
    },
    watch: {
        value() {
            this.searchTextBuf = this.value === null ? '' : this.value;
        },
        searchTextBuf() {
            if (this.timer) {
                clearTimeout(this.timer);
            }

            this.timer = setTimeout(() => {
                clearTimeout(this.timer);

                this.$emit(
                    'input',
                    this.searchTextBuf === '' ? null : this.searchTextBuf
                );
            }, 500);
        },
    },
};
</script>
