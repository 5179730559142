import moment from 'moment';

export const MONTH_NAMES = {
    1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr',
    5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug',
    9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'
};

export function formatNumber(number, maxDecimals = 1) {
    if (number === null || isNaN(number)) {
        return '—';
    } else {
        const multiplier = Math.pow(10, maxDecimals);

        return (Math.round(number * multiplier) / multiplier)
            .toLocaleString('en-US')
            .replace(/,/g, '’');
    }
}

export function formatPrice(price, maxDecimals = 1) {
    if (price === null || isNaN(price)) {
        return '—';
    } else {
        const multiplier = Math.pow(10, maxDecimals);

        return (Math.round(price / 100 * multiplier) / multiplier)
            .toLocaleString('en-US')
            .replace(/,/g, '’');
    }
}

export function formatPercents(percents, forceSign = true) {
    if (percents === null || isNaN(percents)) {
        return '—';
    } else {
        const rounded = Math.round(percents * 100);

        return (forceSign && rounded > 0 ? '+' : '')
            + rounded
                .toLocaleString('en-US')
                .replace(/,/g, '’')
            + '%';
    }
}

export function formatDate(date) {
    if (! date) {
        return '—';
    } else if (! moment.isMoment(date)) {
        return moment(date).format('YYYY. MM. DD');
    } else {
        return date.format('YYYY. MM. DD');
    }
}

export function formatDateTime(date) {
    if (! date) {
        return '—';
    } else if (! moment.isMoment(date)) {
        return moment(date).format('YYYY. MM. DD. HH:mm');
    } else {
        return date.format('YYYY. MM. DD. HH:mm');
    }
}

export function displayDash(data) {
    if (data === null || data === undefined) {
        return '—';
    } else {
        return data;
    }
}

export function downloadBlobResponse(response, filename) {
    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(response);
    a.download = filename;
    a.click();
}
