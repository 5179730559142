<template>
    <tr>
        <td v-for="month in data" :class="{ 'current-month': month.month === currentMonth }">
            {{ month.forecasts_quantity | number }}
        </td>
    </tr>
</template>

<style scoped>
 td:first-child {
     border-left: none;
 }

td.current-month {
    border-left: 3px solid red;
    border-right: 3px solid red;
}
</style>

<script>
export default {
    props: {
        data: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            currentMonth: new Date().getMonth() + 1,
        };
    },
};
</script>
