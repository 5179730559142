<template>
    <div class="y-scrollable-block position-relative overflow-auto">
        <table class="table table-bordered table-has-sticky table-sticky-col table-sticky-row table-layout-fixed">
            <thead>
            <tr>
                <th>Distributor name</th>
                <th>Open orders with 7F</th>
                <th>Open retailer orders</th>
                <th>Stock</th>
                <th>Order evolution</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="distributor in data" @click="navigateToDetails(distributor.distributor.id)">
                <th class="font-weight-normal">{{ distributor.distributor.short_name }}</th>
                <td>{{ distributor.statistics.unconfirmed_orders_count | number }}</td>
                <td>{{ distributor.statistics.open_retailer_orders_count | number }}</td>
                <td>{{ distributor.statistics.stock | number }}</td>
                <td><number-value :n="distributor.statistics.orders_trend" percents></number-value></td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
th, td {
    width: 200px;
}

tbody tr {
    cursor: pointer;
}
</style>

<script>
export default {
    props: {
        data: {
            required: true,
            type: Array,
        },
    },
    methods: {
        navigateToDetails(id) {
            this.$router.push({ name: 'b2b.distributors.details', params: { id: id } });
        }
    }
};
</script>
