<template>
    <tr>
        <slot></slot>
        <td>{{ data.stock_7f | number }}</td>
        <td>{{ data.stock_time_distributor | number }}</td>
        <td>{{ data.stock_time_retailer | number }}</td>
        <td>{{ data.stock_time_total | number }}</td>
    </tr>
</template>

<script>
export default {
    props: {
        data: {
            required: true,
            type: Object,
        },
    },
};
</script>
