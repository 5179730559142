<template>
    <table class="table table-bordered table-layout-fixed">
        <thead>
        <tr>
            <th>Products</th>
            <th>Sales</th>
            <th>LYTD</th>
            <th>Sell out</th>
            <th>Customer reg</th>
            <th>Stock QTY</th>
        </tr>
        </thead>
        <tbody>
        <sales-flash-table-row :data="data.total" />
        <sales-flash-table-row v-for="group in data.groups" :key="group.name" :data="group" />
        </tbody>
    </table>
</template>

<style scoped>
.table {
    margin-top: 0;
    margin-bottom: 0;
}
</style>

<script>
import SalesFlashTableRow from './SalesFlashTableRow';

export default {
    components: {
        SalesFlashTableRow
    },
    props: {
        data: {
            required: true,
            type: Object,
        },
    },
};
</script>
