<template>
    <div class="y-scrollable-block position-relative overflow-auto" v-if="data[`${clientType}s`].length">
        <table class="table table-bordered table-has-sticky table-sticky-row table-sticky-col table-layout-fixed">
            <thead>
            <tr>
                <th>{{ headerLabel }}</th>
                <th>Total Stock</th>
                <th>LY Total Stock</th>
                <th>Total Sales</th>
                <th>LY Total Sales</th>

                <template v-for="series in data[`${clientType}s`][0].series">
                    <th :class="['collapsible-col', { 'collapsible-col-open': seriesOpenState[series.series] }]"
                        @click="toggleCollapsed(series.series)">
                        {{ data.referenced_data.product_series[series.series].name }}
                    </th>

                    <template v-if="seriesOpenState[series.series]">
                        <th v-for="product in series.products" class="collapsible-col-open font-weight-normal">
                            {{ data.referenced_data.products[product.product].product_number }}
                        </th>
                    </template>
                </template>
            </tr>
            </thead>
            <tbody>
            <tr v-for="client in data[`${clientType}s`]">
                <th class="font-weight-normal">{{ client[clientType].short_name }}</th>
                <td>{{ client.total[`stock_${clientType}`] | number }}</td>
                <td><number-value :n="client.total[`stock_${clientType}_trend`]" percents></number-value></td>
                <td>{{ client.total[`sales_${clientType}_quantity`] | number }}</td>
                <td><number-value :n="client.total[`sales_${clientType}_quantity_trend`]" percents></number-value></td>

                <template v-for="series in client.series">
                    <td :class="{ 'collapsible-col-open': seriesOpenState[series.series] }">
                        {{ series.total[`stock_${clientType}`] | number }}
                    </td>

                    <template v-if="seriesOpenState[series.series]">
                        <td v-for="product in series.products" class="collapsible-col-open">
                            {{ product.statistics[`stock_${clientType}`] | number }}
                        </td>
                    </template>
                </template>
            </tr>
            </tbody>
        </table>
    </div>
    <div v-else class="no-data-available">
        No data available
    </div>
</template>

<style scoped>
th, td {
    width: 120px;
}
</style>

<script>
import ProductLevelTableRow from './ProductLevelTableRow';

export default {
    components: {
        ProductLevelTableRow,
    },
    props: {
        data: {
            required: true,
            type: Object,
        },
        clientType: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            seriesOpenState: {},
        };
    },
    computed: {
        headerLabel() {
            return _.startCase(this.clientType) + 's';
        },
    },
    methods: {
        toggleCollapsed(seriesId) {
            this.$set(this.seriesOpenState, seriesId, ! this.seriesOpenState[seriesId]);
        },
    },
};
</script>
