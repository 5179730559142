<template>
    <tr :class="{ 'marked': marked }">
        <slot></slot>
        <td>{{ data.zurich | number }}</td>
        <td>{{ data.hk | number }}</td>
        <td>{{ data.germany | number }}</td>
        <td>{{ data.la | number }}</td>
        <td>{{ data['113'] | number }}</td>
        <td>{{ data.transit | number }}</td>
        <td>{{ data.others | number }}</td>
        <td>{{ data.total | number }}</td>
    </tr>
</template>

<style lang="scss" scoped>
$red-border: 3px solid red;

tr.marked {
    th, td {
        border-top: $red-border;
        border-bottom: $red-border;
    }

    th:first-child {
        border-left: $red-border;
    }

    td:last-child {
        border-right: $red-border;
    }
}
</style>

<script>
export default {
    props: {
        data: {
            required: true,
            type: Object,
        },
        marked: {
            required: false,
            type: Boolean
        }
    },
};
</script>
