<template>

    <div v-if="ipc">
        <h2>{{ ipc.reference }}</h2>

        <div class="d-flex justify-content-end align-items-end mb-1">
            <router-link :to="{ name: 'legal.ipc.edit', params: { id: ipc.id } }" class="text-primary font-weight-bold mr-4" v-if="canManage">
                Modify this IP
            </router-link>
            <router-link :to="{ name: 'legal.ipc.manage-attachments', params: { id: ipc.id } }" class="text-primary font-weight-bold mr-4" v-if="canManage">
                Manage files
            </router-link>
            <a href="#" @click="$refs.deleteConfirmationModal.open()" class="text-primary font-weight-bold mr-4" v-if="canManage"
                >Delete this IP</a>
        </div>

        <table class="table table-bordered table-layout-fixed mb-3">
            <thead>
            <tr>
                <th>Name</th>
                <th>Country</th>
                <th v-if="ipc.type === 'trademark'">Class</th>
                <th>Next renewal</th>
                <th>Status</th>
                <th>Last renewal</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{{ ipc.name }}</td>
                <td>{{ ipc.country_id }}</td>
                <td v-if="ipc.type === 'trademark'">{{ ipcClass || ipc.class }}</td>
                <td>{{ ipc.next_renewal | date }}</td>
                <td>{{ ipc.status_label }}</td>
                <td>
                    {{ ipc.last_renewal | date }}
                    <span v-if="lastRenewalAttachment" class="cursor-pointer" @click="downloadDocument(lastRenewalAttachment)">
                        <inline-svg src="/assets/icons/file--red.svg">
                        </inline-svg>
                    </span>
                </td>
            </tr>
            </tbody>
        </table>

        <table class="table table-bordered table-layout-fixed mb-3">
            <thead>
            <tr>
                <th>CType</th>
                <th>Owner</th>
                <th>Renewal cost</th>
                <th>Interval / Periodicity</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{{ ipc.ctype }}</td>
                <td>{{ ipc.owner }}</td>
                <td>
                    USD {{ ipc.renewal_cost | price }}.-
                    <template v-if="! ipcClass && ipc.renewal_cost !== ipc.total_renewal_cost">
                        <br>(USD {{ ipc.total_renewal_cost | price }}.- in total)
                    </template>
                </td>
                <td>{{ ipc.periodicity }} ({{ ipc.current_period || '?' }} of {{ ipc.period_count }})</td>
            </tr>
            </tbody>
        </table>

        <div class="d-flex mb-3">
            <attachments-table title="Application" name-col-title="Application nr." :attachments="applicationAttachments" :priority-date-col="true" />
            <attachments-table title="First registration" name-col-title="Registration nr." :attachments="registrationAttachments" />
        </div>

        <div class="d-flex mb-3">
            <div class="table-bg-color" v-if="ipc.type === 'trademark'"><attachments-table title="Proof of use" name-col-title="Short description" :attachments="proofOfUseAttachments" /></div>
            <div class="table-bg-color"><attachments-table title="Other documents" name-col-title="Short description" :attachments="otherAttachments" /></div>
        </div>

        <table class="table table-bordered table-layout-fixed mb-3">
            <thead>
            <tr>
                <th>Lawyer</th>
                <th>Comments</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    <a v-if="ipc.lawyer" href="#" @click.prevent="$bvModal.show('lawyer-modal');" class="text-primary">
                        {{ ipc.lawyer.name }}
                    </a>
                    <template v-else>
                        —
                    </template>
                </td>
                <td>{{ ipc.comments }}</td>
            </tr>
            </tbody>
        </table>

        <documents-modal :ipc="ipc" />

        <lawyer-modal v-if="ipc.lawyer" :lawyer="ipc.lawyer" />

        <simple-modal ref="deleteConfirmationModal" icon="warning" confirm @ok="deleteIPC()">
            Warning! This will delete all classes of this IP! Are you sure you want to permanently delete this IP?
        </simple-modal>
    </div>

</template>

<script>
import CurrentUserService from '../../../js/services/CurrentUserService';
import StatisticsService from '../../../js/services/StatisticsService';
import DocumentsModal from '../../components/legal/DocumentsModal';
import LawyerModal from '../../components/legal/LawyerModal';
import AttachmentsTable from '../../components/legal/AttachmentsTable';
import {downloadBlobResponse} from "../../../js/helpers";

export default {
    components: {
        LawyerModal,
        DocumentsModal,
        AttachmentsTable,
    },
    data() {
        return {
            ipc: null,
            canManage: false,
        };
    },
    computed: {
        ipcId() {
            return this.$route.params.id;
        },
        ipcClass() {
            return this.$route.query.class;
        },
        applicationAttachments() {
            const attachment = this.ipc.attachments.find(a => a.type === 'application');

            return [{
                name: this.ipc.application_number,
                date: this.ipc.application_date,
                priority_date: this.ipc.priority_date,
                file_name: attachment ? attachment.file_name : null,
                url: attachment ? attachment.url : null,
            }];
        },
        registrationAttachments() {
            const attachment = this.ipc.attachments.find(a => a.type === 'first_registration');

            if (! attachment) {
                return [];
            }

            return [{
                name: this.ipc.registration_number,
                date: this.ipc.first_registration_date,
                file_name: attachment ? attachment.file_name : null,
                url: attachment ? attachment.url : null,
            }];
        },
        lastRenewalAttachment() {
            const attachment = this.ipc.attachments.find(a => a.type === 'last_renewal');

            if (! attachment) {
                return null;
            }

            return [{
                file_name: attachment.file_name,
                url: attachment.url,
            }];
        },
        proofOfUseAttachments() {
            return this.ipc.attachments.filter(a => a.type === 'proof_of_use')
        },
        otherAttachments() {
            return this.ipc.attachments.filter(a => a.type === 'default')
        },
    },
    watch: {
        async ipcId() {
            await this.loadIPC();
        },
    },
    async mounted() {
        this.canManage = (await CurrentUserService.get()).can.includes('manage-ipcs');
        await this.loadIPC();
    },
    methods: {
        async loadIPC() {
            this.ipc = await StatisticsService.getIPC(this.ipcId);
        },
        async downloadDocument(attachment) {
            downloadBlobResponse(
                await StatisticsService.downloadFile(attachment.url),
                attachment.file_name
            );
        },
        async deleteIPC() {
            await StatisticsService.deleteIPC(this.ipcId);

            await this.$router.replace({ name: 'legal.' + this.ipc.type });
        },
    },
};
</script>
