<template>
    <vue2-date-range-picker :date-range="value" @update="handleUpdate"
            ref="picker"
            control-container-class="form-control calendar-placeholder"
            :opens="opens" :ranges="ranges" show-dropdowns
            :single-date-picker="! range">
        <template #input="picker">
            <span v-if="! value.startDate && ! value.endDate"
                  class="placeholder-text">
                {{ range ? 'Select dates' : 'Select date' }}
            </span>
            <span v-else-if="range">
                {{ picker.startDate | date }} - {{ picker.endDate | date }}
            </span>
            <span v-else>
                {{ picker.startDate | date }}
            </span>
        </template>
        <template #footer="data">
            <div class="p-2 text-right">
                <button v-if="clearable" class="btn btn-secondary btn-sm"
                        :disabled="data.in_selection || ! value.startDate"
                        @click="clear" type="button">
                    Clear
                </button>
                <button class="btn btn-secondary btn-sm" @click="data.clickCancel" type="button">
                    Cancel
                </button>
                <button class="btn btn-primary btn-sm" :disabled="data.in_selection"
                        @click="data.clickApply" type="button">
                    Apply
                </button>
            </div>
        </template>
    </vue2-date-range-picker>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment';

export default {
    components: {
        'vue2-date-range-picker': DateRangePicker,
    },
    props: {
        value: {
            required: true,
            type: Object,
        },
        range: {
            required: true,
            type: Boolean,
        },
        clearable: {
            required: false,
            type: Boolean,
            default: true,
        },
        opens: {
            required: false,
            type: String,
            default: 'right',
        },
    },
    data() {
        let ranges;
        if (this.range) {
            let today = new Date();
            today.setHours(0, 0, 0, 0);

            let yesterday = new Date();
            yesterday.setDate(today.getDate() - 1),
            yesterday.setHours(0, 0, 0, 0);

            ranges = {
                'This month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
                'Last month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                'This year': [moment().startOf('year').toDate(), moment().endOf('year').toDate()],
                'Last year': [moment().subtract(1, 'year').startOf('year').toDate(), moment().subtract(1, 'year').endOf('year').toDate()],
            };
        } else {
            ranges = false;
        }

        return {
            ranges,
        };
    },
    methods: {
        handleUpdate(value) {
            if (value.startDate && value.endDate) {
                this.$emit('input', value);
            } else {
                this.clear();
            }
        },
        clear() {
            this.$emit('input', {
                startDate: null,
                endDate: null,
            });

            this.$refs.picker.togglePicker(false);
        },
    },
};
</script>
