export default new class {
    constructor() {
        this.$_groups = null;
        this.$_series = null;
    }

    getGroups() {
        if (! this.$_groups) {
            this.$_groups = axios.get('product-groups')
                .then(response => response.data.product_groups);
        }

        return this.$_groups;
    }

    getSeries() {
        if (! this.$_series) {
            this.$_series = axios.get('product-series')
                .then(response => response.data.product_series);
        }

        return this.$_series;
    }
};
