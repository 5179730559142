import EventBus from '../event-bus';

export default new class {
    constructor() {
        this.$_dueCount = {};
        this.$_legalMeta = null;
    }

    async boot() {
        EventBus.$on('got-access-token', async () => {
            await this.refresh();
        });
    }

    async refresh() {
        this.$_dueCount = (await axios.get('cockpit/legal/certificates/due-count', {__hideSpinner: true})).data;

        EventBus.$emit('due-ip-count-refreshed', this.$_dueCount);
    }

    async getLegalMeta() {
        if (! this.$_legalMeta) {
            this.$_legalMeta = axios.get('cockpit/legal/meta')
                .then(response => response.data);
        }

        return this.$_legalMeta;
    }

    get dueCount() {
        return this.$_dueCount;
    }
};
