<template>
    <div id="authPanel" class="card">
        <router-view></router-view>
    </div>
</template>

<style scoped>
#authPanel {
    max-width: 506px;
    margin: 20vh auto 0 auto;
}

#authPanel .card-body {
    padding: 37px 63px;
}
</style>

<script>
export default {};
</script>
