<template>
    <div>
        <h3 class="mb-0 mt-3">{{ product.product.product_number }}</h3>
        <p class="mb-2">{{ product.product.matchcode }}</p>

        <div class="row">
            <div class="col text-nowrap">
                Launch date:
                <span class="product-header-value">
                    {{ product.product.launch_date | date }}
                </span>
            </div>
            <div class="col-auto">
                Total Stock:
                <span class="product-header-value">
                    {{ product.total.stock_7f | number }}
                </span>
            </div>
            <div class="col-auto">
                Avg monthly sales of last 6mts:
                <span class="product-header-value">

                    {{ product.total.avg_sales_7f_quantity | number }}
                </span>
            </div>
        </div>

        <div class="position-relative overflow-auto" ref="table-container">
            <table class="table table-bordered table-has-sticky table-sticky-col table-layout-fixed mb-0 table-plan">
                <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th
                        v-for="month in product.months"
                        :class="{ 'current-month current-month-first': isCurrentMonth(month.year, month.month) }"
                        :ref="isCurrentMonth(month.year, month.month) ? 'current-month' : undefined"
                    >
                        {{ month.month | month }}, {{ month.year }}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th>Forecast markets</th>
                    <td v-for="month in product.months" :class="{ 'current-month': isCurrentMonth(month.year, month.month) }">
                        {{ month.forecasts_quantity | number }}
                    </td>
                </tr>
                <tr>
                    <th>Actual/forecast 7F</th>
                    <td v-for="month in product.months"
                        :class="{ 'current-month': isCurrentMonth(month.year, month.month) }">
                        <div v-if="editable7fForecastProductId === product.product.id && editable7fForecastMonthId === month.month_id"
                             class="input-group input-group-sm">
                            <input type="text" class="form-control form-control-sm text-center" v-model="editable7fForecastQuantity">
                            <div class="input-group-append">
                                <button type="button" class="btn btn-primary btn-sm" @click="update7fForecast(product.product, month)">✓</button>
                            </div>
                        </div>
                        <div v-else @click="show7fForecastInput(product.product, month)" :class="{'cursor-pointer': canEdit7fForecast}">
                            <template v-if="month.month_status !== 'future'">
                                {{ month.sales_7f_quantity | number }}/{{ month.forecasts_7f_quantity | number }}
                            </template>
                            <template v-else>
                                {{ month.forecasts_7f_quantity | number }}
                            </template>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>Sell out Distributor</th>
                    <td v-for="month in product.months" :class="{ 'current-month': isCurrentMonth(month.year, month.month) }">
                        {{ month.sales_distributor_quantity | number }}
                    </td>
                </tr>
<!--                <tr>-->
<!--                    <th>Sell out Retailer</th>-->
<!--                    <td v-for="month in product.months" :class="{ 'current-month': isCurrentMonth(month.year, month.month) }">-->
<!--                        {{ month.sales_retailer_quantity | number }}-->
<!--                    </td>-->
<!--                </tr>-->
                <tr>
                    <th>Purchase</th>
                    <td v-for="month in product.months" :class="{ 'current-month': isCurrentMonth(month.year, month.month) }">
                        {{ month.purchases_quantity | number }}
                    </td>
                </tr>
                <tr>
                    <th class="marked">7F balance stock end month</th>
                    <td v-for="month in product.months" :class="['marked', { 'current-month': isCurrentMonth(month.year, month.month) }]">
                        {{ month.stock_7f_end_month | number }}
                    </td>
                </tr>
                <tr>
                    <th>D balance stock end month</th>
                    <td v-for="month in product.months" :class="{ 'current-month': isCurrentMonth(month.year, month.month) }">
                        {{ month.stock_distributor | number }}
                    </td>
                </tr>
                <tr>
                    <th>R balance stock end month</th>
                    <td v-for="month in product.months" :class="{ 'current-month current-month-last': isCurrentMonth(month.year, month.month) }">
                        {{ month.stock_retailer | number }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style lang="scss" scoped>
th, td {
    width: 150px;
}

.product-header-value {
    position: relative;
    top: -3px;
    display: inline-block;
    margin-left: 15px;
    vertical-align: middle;
    font-weight: 500;
    font-size: 150%;
}

$red-border: 3px solid red;

.table-plan {
    th.current-month,
    td.current-month {
        border-left: $red-border;
        border-right: $red-border;

        &-first {
            border-top: $red-border;
        }

        &-last {
            border-bottom: $red-border;
        }
    }

    th.marked {
        border-left: $red-border;
        border-top: $red-border;
        border-bottom: $red-border;
    }

    td.marked {
        border-top: $red-border;
        border-bottom: $red-border;
    }
}
</style>

<script>
export default {
    props: {
        product: {
            required: true,
            type: Object,
        },
        canEdit7fForecast: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            editable7fForecastProductId: null,
            editable7fForecastMonthId: null,
            editable7fForecastQuantity: null,
        };
    },
    methods: {
        show7fForecastInput(product, month) {
            if (! this.canEdit7fForecast) {
                return;
            }

            this.editable7fForecastProductId = product.id;
            this.editable7fForecastMonthId = month.month_id;
            this.editable7fForecastQuantity = month.forecasts_7f_quantity || 0;
        },
        async update7fForecast(product, month) {
            this.$emit('update-7f-forecast', {
                product_id: product.id,
                year: month.year,
                month: month.month,
                quantity: this.editable7fForecastQuantity,
            });

            this.editable7fForecastProductId = null;
            this.editable7fForecastMonthId = null;
            this.editable7fForecastQuantity = null;
        },
        isCurrentMonth(year, month) {
            const date = new Date();
            return date.getFullYear() === year && date.getMonth() === month - 1;
        },
    },
    mounted() {
        const currentMonthRefs = this.$refs['current-month'];
        if (currentMonthRefs && currentMonthRefs.length) {
            // scroll to current month
            this.$refs['table-container'].scrollLeft = Math.max(currentMonthRefs[0].offsetLeft - 150 - 2, 0);
        }
    },
};
</script>
