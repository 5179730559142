<template>
    <div class="y-scrollable-block position-relative overflow-auto">
        <table class="table table-bordered table-layout-fixed cell-align-left">
            <thead>
            <tr>
                <th>Products</th>
                <th>QTY</th>
                <th>Value</th>
            </tr>
            </thead>
            <tbody>
            <sales-flash-table-row :data="data.total" :show-only="show">
                <td>Total</td>
            </sales-flash-table-row>
            <sales-flash-table-row v-for="group in data.groups" :data="group.statistics" :key="group.group.id" :show-only="show">
                <td>{{ group.group.name }}</td>
            </sales-flash-table-row>
            <sales-flash-table-row :data="data.others" :show-only="show">
                <td>Others</td>
            </sales-flash-table-row>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
.table {
    margin-top: 0;
    margin-bottom: 0;
}
</style>

<script>
import SalesFlashTableRow from './SalesFlashTableRow';

export default {
    components: {
        SalesFlashTableRow
    },
    props: {
        data: {
            required: true,
            type: Object,
        },
        show: {
            required: true,
            type: String,
        }
    },
};
</script>
