export default new class {
    async index() {
        return (await axios.get('users/cockpit')).data.users;
    }

    async invite(email, can) {
        return (await axios.post('users/cockpit', { email, can })).data.user;
    }

    async delete(id) {
        await axios.delete(`users/cockpit/${id}`);
    }
};
