window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

require('./axios');

/**
 * On some mobile browsers, CSS viewport height (vh) is not calculated correctly,
 * because the heights of top or bottom bars are not subtracted from the viewport height.
 * We try to use window.innerHeight in CSS if the browser supports CSS variables.
 */

window.addEventListener('resize', setVhProp);
setVhProp();

function setVhProp() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}
