<template>
    <div class="position-relative overflow-auto" ref="table-container">
        <table class="table table-bordered table-has-sticky table-sticky-col table-layout-fixed table-budget">
            <thead>
            <tr>
                <th>&nbsp;</th>
                <th
                    v-for="month in data"
                    :class="{ 'current-month current-month-first': isCurrentMonth(month.year, month.month) }"
                    :ref="isCurrentMonth(month.year, month.month) ? 'current-month' : undefined"
                >
                    {{ month.month | month }}, {{ month.year }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th>Monthly budget</th>
                <td v-for="month in data" :class="{ 'current-month': isCurrentMonth(month.year, month.month) }">
                    {{ month.budget | number }}
                </td>
            </tr>
            <tr>
                <th>Actual/forecast markets</th>
                <td v-for="month in data" :class="{ 'current-month': isCurrentMonth(month.year, month.month) }">
                    <template v-if="month.month_status !== 'future'">
                        {{ month.sales_7f_quantity | number }}/{{ month.forecasts_quantity | number }}
                    </template>
                    <template v-else>
                        {{ month.forecasts_quantity | number }}
                    </template>
                </td>
            </tr>
            <tr>
                <th>Delta budget</th>
                <td v-for="month in data" :class="{ 'current-month': isCurrentMonth(month.year, month.month) }">
                    <number-value :n="month.delta_markets" percents></number-value>
                </td>
            </tr>
            <tr>
                <th>Actual/forecast 7F</th>
                <td v-for="month in data" :class="{ 'current-month': isCurrentMonth(month.year, month.month) }">
                    <template v-if="month.month_status !== 'future'">
                        {{ month.sales_7f_quantity | number }}/{{ month.forecasts_7f_quantity | number }}
                    </template>
                    <template v-else>
                        {{ month.forecasts_7f_quantity | number }}
                    </template>
                </td>
            </tr>
            <tr>
                <th>Delta BDGT</th>
                <td v-for="month in data" :class="{ 'current-month current-month-last': isCurrentMonth(month.year, month.month) }">
                    <number-value :n="month.delta_7f" percents></number-value>
                </td>
            </tr>
            </tbody>
        </table>

        <table class="table table-bordered table-has-sticky table-sticky-col table-layout-fixed table-purchase">
            <tbody>
            <tr>
                <th>Purchase</th>
                <td v-for="month in data" :class="{ 'current-month current-month-first': isCurrentMonth(month.year, month.month) }">
                    {{ month.purchases_quantity | number }}
                </td>
            </tr>
            <tr>
                <th>7F Total Stock</th>
                <td v-for="month in data" :class="{ 'current-month': isCurrentMonth(month.year, month.month) }">
                    {{ month.stock_7f | number }}
                </td>
            </tr>
            <tr>
                <th>7F Free Stock</th>
                <td v-for="month in data" :class="{ 'current-month': isCurrentMonth(month.year, month.month) }">
                    {{ month.stock_7f_free | number }}
                </td>
            </tr>
            <tr>
                <th>7F Reserved Stock</th>
                <td v-for="month in data" :class="{ 'current-month current-month-last': isCurrentMonth(month.year, month.month) }">
                    {{ month.stock_7f_reserved | number }}
                </td>
            </tr>
            </tbody>
        </table>

        <table class="table table-bordered table-has-sticky table-sticky-col table-layout-fixed table-month-stock">
            <tbody>
            <tr>
                <th>Month Stock</th>
                <td v-for="month in data" class="font-weight-bold" :class="{ 'current-month': isCurrentMonth(month.year, month.month) }">
                    <month-stock-value :month-stock="month.month_stock"
                                       :stock="month.stock_7f" />
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<style lang="scss" scoped>
th, td {
    width: 150px;
}

$red-border: 3px solid red;

.table-budget {
    th.current-month,
    td.current-month {
        border-left: $red-border;
        border-right: $red-border;

        &-first {
            border-top: $red-border;
        }

        &-last {
            border-bottom: $red-border;
        }
    }
}

.table-purchase {
    td.current-month {
        border-left: $red-border;
        border-right: $red-border;

        &-first {
            border-top: $red-border;
        }

        &-last {
            border-bottom: $red-border;
        }
    }
}

.table-month-stock {
    th {
        border-top: $red-border;
        border-bottom: $red-border;
        border-left: $red-border;
    }

    td {
        border-top: $red-border;
        border-bottom: $red-border;

        &.current-month {
            border-left: $red-border;
            border-right: $red-border;
        }
    }

    td:last-child {
        border-right: $red-border;
    }
}
</style>

<script>
import MonthStockValue from './MonthStockValue';

export default {
    components: {
        MonthStockValue,
    },
    props: {
        data: {
            required: true,
            type: Array,
        },
    },
    methods: {
        isCurrentMonth(year, month) {
            const date = new Date();
            return date.getFullYear() === year && date.getMonth() === month - 1;
        }
    },
    mounted() {
        const currentMonthRefs = this.$refs['current-month'];
        if (currentMonthRefs && currentMonthRefs.length) {
            // scroll to current month
            this.$refs['table-container'].scrollLeft = Math.max(currentMonthRefs[0].offsetLeft - 150 - 2, 0);
        }
    }
};
</script>
