import EventBus from '../event-bus';

export default new class {
    constructor() {
        this.$_count = 0;
    }

    work() {
        if (this.$_count === 0) {
            EventBus.$emit('spinner-state-change', true);
        }

        this.$_count += 1;
    }

    done() {
        if (this.$_count <= 1) {
            EventBus.$emit('spinner-state-change', false);
        }

        if (this.$_count > 1) {
            this.$_count -= 1;
        } else {
            this.$_count = 0;
        }
    }
};
