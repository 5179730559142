<template>
    <simple-modal ref="errorModal" icon="error" cancel-button-label="Close">
        <p>
            {{ mainMessage }}
        </p>

        <ul v-if="messageList.length" class="text-left">
            <li v-for="message in messageList">{{ message }}</li>
        </ul>
    </simple-modal>
</template>

<script>
import EventBus from '../../js/event-bus';

export default {
    data() {
        return {
            mainMessage: null,
            messageList: [],
        };
    },
    mounted() {
        EventBus.$on('show-error-modal', payload => {
            this.mainMessage = payload.mainMessage;

            if (payload.messageList) {
                this.messageList = payload.messageList;
            } else {
                this.messageList = [];
            }

            this.$refs.errorModal.open();
        });
    },
}
</script>
