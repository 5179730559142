import AuthLayout from '../vue/layouts/AuthLayout';
import LoginPage from '../vue/pages/auth/LoginPage';
import ForgotPasswordPage from '../vue/pages/auth/ForgotPasswordPage';
import ResetPasswordPage from '../vue/pages/auth/ResetPasswordPage';
import CockpitLayout from '../vue/layouts/CockpitLayout';
import SettingsPage from '../vue/pages/SettingsPage';
import AuthService from './services/AuthService';
import config from './config';
import DashboardPage from '../vue/pages/DashboardPage';
import PurchasePlanPage from '../vue/pages/PurchasePlanPage';
import SalesAndStockPage from '../vue/pages/SalesAndStockPage';
import TimeToMarketPage from '../vue/pages/TimeToMarketPage';
import DistributorsPage from '../vue/pages/DistributorsPage';
import DistributorDetailsPage from '../vue/pages/DistributorDetailsPage';
import RetailersPage from '../vue/pages/RetailersPage';
import RetailerDetailsPage from '../vue/pages/RetailerDetailsPage';
import SalesFlashPage from '../vue/pages/SalesFlashPage';
import StockConsolidationPage from '../vue/pages/StockConsolidationPage';
import NotificationsPage from '../vue/pages/NotificationsPage';
import ReportsPage from '../vue/pages/ReportsPage';
import OverviewPage from '../vue/pages/legal/OverviewPage';
import CertificateListPage from '../vue/pages/legal/CertificateListPage';
import CertificateDetailsPage from '../vue/pages/legal/CertificateDetailsPage';
import LawyerListPage from '../vue/pages/legal/LawyerListPage';
import LawyerFormPage from '../vue/pages/legal/LawyerFormPage';
import CertificateFormPage from '../vue/pages/legal/CertificateFormPage';
import AttachmentsFormPage from '../vue/pages/legal/AttachmentsFormPage';
import ImportFormPage from '../vue/pages/legal/ImportFormPage';

export const routes = [
    { path: '', redirect: () => {
        return AuthService.getAccessToken()
            ? config.homeRoute
            : { name: 'auth.login' };
    }},
    { path: '/auth', component: AuthLayout, children: [
        { path: '', component: LoginPage, name: 'auth.login' },
        { path: 'forgot-password', component: ForgotPasswordPage, name: 'auth.forgot' },
        { path: 'reset-password/:token', component: ResetPasswordPage, name: 'auth.reset' },
        { path: 'setup-password/:token', component: ResetPasswordPage, name: 'auth.setup', meta: { setup: true } },
    ]},
    { path: '', component: CockpitLayout, children: [
        { path: 'b2b/dashboard', component: DashboardPage, name: 'b2b.dashboard' },
        { path: 'b2b/plan', component: PurchasePlanPage, name: 'b2b.plan' },
        { path: 'b2b/sales-and-stock', component: SalesAndStockPage, name: 'b2b.sales-and-stock' },
        { path: 'b2b/time-to-market', component: TimeToMarketPage, name: 'b2b.time-to-market' },
        { path: 'b2b/distributors', component: DistributorsPage, name: 'b2b.distributors' },
        { path: 'b2b/distributors/:id', component: DistributorDetailsPage, name: 'b2b.distributors.details' },
        { path: 'b2b/retailers', component: RetailersPage, name: 'b2b.retailers' },
        { path: 'b2b/retailers/:id', component: RetailerDetailsPage, name: 'b2b.retailers.details' },
        { path: 'b2b/sales-flash', component: SalesFlashPage, name: 'b2b.sales-flash' },
        { path: 'b2b/stock-consolidation', component: StockConsolidationPage, name: 'b2b.stock-consolidation' },
        { path: 'b2c/reports', component: ReportsPage, name: 'b2c.reports' },
        { path: 'settings', component: SettingsPage, name: 'settings' },
        { path: 'notifications', component: NotificationsPage, name: 'notifications' },
        { path: 'legal/overview', component: OverviewPage, name: 'legal.overview' },
        { path: 'legal/trademark', component: CertificateListPage, name: 'legal.trademark', meta: { type: 'trademark' } },
        { path: 'legal/design', component: CertificateListPage, name: 'legal.design', meta: { type: 'design' } },
        { path: 'legal/trademark/:id', component: CertificateDetailsPage, name: 'legal.trademark.details' },
        { path: 'legal/design/:id', component: CertificateDetailsPage, name: 'legal.design.details' },
        { path: 'legal/ipc/create', component: CertificateFormPage, name: 'legal.ipc.create' },
        { path: 'legal/ipc/import', component: ImportFormPage, name: 'legal.ipc.import' },
        { path: 'legal/ipc/:id', component: CertificateFormPage, name: 'legal.ipc.edit' },
        { path: 'legal/ipc/:id/manage-attachments', component: AttachmentsFormPage, name: 'legal.ipc.manage-attachments' },
        { path: 'legal/lawyers', component: LawyerListPage, name: 'legal.lawyer' },
        { path: 'legal/lawyers/create', component: LawyerFormPage, name: 'legal.lawyer.create' },
        { path: 'legal/lawyers/:id', component: LawyerFormPage, name: 'legal.lawyer.edit' },
    ]},
];
