<template>
    <a href="#" @click.prevent="exportXls">Export to XLS</a>
</template>

<style scoped>
a {
    color: #e31837;
    font-weight: 700;
}
</style>

<script>
export default {
    methods: {
        exportXls() {
            alert("export");
        }
    }
};
</script>
