<template>
    <b-modal v-if="ipc && attachmentTypes" id="ip-documents-modal" title="Documents" size="xl" scrollable hide-footer>
        <table class="table table-bordered table-has-sticky table-sticky-row table-layout-fixed">
            <colgroup>
                <col style="width: 150px;">
                <col />
                <col style="width: 150px;">
            </colgroup>
            <thead>
            <tr>
                <th>Type</th>
                <th>Name</th>
                <th>Download</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="attachment in ipc.attachments">
                <td><strong>{{ attachmentTypes[attachment.type] }}</strong></td>
                <td>{{ attachment.name }}</td>
                <td>
                    <span class="cursor-pointer" @click="download(attachment)">
                        <inline-svg src="/assets/icons/file--red.svg" />
                    </span>
                </td>
            </tr>
            </tbody>
        </table>
    </b-modal>
</template>

<style scoped>

</style>

<script>
import {downloadBlobResponse} from '../../../js/helpers';
import StatisticsService from '../../../js/services/StatisticsService';
import LegalService from '../../../js/services/LegalService';

export default {
    props: {
        ipc: {
            required: false,
            type: Object,
        },
    },
    data() {
        return {
            attachmentTypes: null,
        };
    },
    async mounted() {
        const meta = await LegalService.getLegalMeta();
        const types = {};

        for (const type of meta.attachment_types) {
            types[type.id] = type.label;
        }

        this.attachmentTypes = types;
    },
    methods: {
        async download(attachment) {
            downloadBlobResponse(
                await StatisticsService.downloadFile(attachment.url),
                attachment.file_name
            );
        },
    },
};
</script>
