<template>
    <div>
        <h2>Notifications</h2>

        <template v-for="(notifications, state) in allNotifications">
            <template v-if="notifications.length">
                <h3>{{ state === 'unread' ? 'New' : 'Earlier' }}</h3>

                <div v-for="notification in notifications" class="row mb-2">
                    <div class="col-auto py-3">
                        <button class="btn btn-link btn-link-regular text-danger font-weight-bold" @click="deleteNotification(notification)">Delete</button>
                    </div>
                    <div class="col py-3 notification" :class="`notification-${state}`">
                        <div class="notification-text" @click="navigate(notification)" :class="{'cursor-pointer': notification.route}">
                            <inline-svg src="/assets/icons/bell--black-filled.svg">
                            </inline-svg>
                            <span v-html="notification.html" class="ml-3"></span>
                        </div>
                        <div class="ml-2 font-size-12 notification-date">
                            {{ notification.created_at | datetime }}
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.notification {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #ffffff;
    border-style: solid;
    border-width: 2px;

    &.notification-read {
        border-color: var(--border-color);

        svg {
            color: var(--secondary);
        }
    }

    &.notification-unread {
        border-color: var(--primary);

        svg {
            color: var(--primary);
        }
    }

    .notification-text {
        flex: 1 1 0;
        min-width: 130px;
    }

    .notification-date {
        flex: 0 1 auto;
    }
}
</style>

<script>
import NotificationService from '../../js/services/NotificationService';

export default {
    data() {
        return {
            allNotifications: {
                unread: [],
                read: [],
            },
            readTillRequestTimeout: null,
        };
    },
    async mounted() {
        await this.fetchNotifications();

        // Mark all notifications as read after staying on the page for a few secs
        this.readTillRequestTimeout = setTimeout(async () => {
            if (this.allNotifications.unread.length) {
                await NotificationService.readTill(
                    this.allNotifications.unread[0].id
                );
            }
        }, 2000);
    },
    beforeDestroy() {
        clearTimeout(this.readTillRequestTimeout);
    },
    methods: {
        async fetchNotifications() {
            this.allNotifications.read = [];
            this.allNotifications.unread = [];

            const notifications = await NotificationService.index();

            for (const notification of notifications) {
                if (notification.read_at) {
                    this.allNotifications.read.push(notification);
                } else {
                    this.allNotifications.unread.push(notification);
                }
            }
        },
        async deleteNotification(notification) {
            await NotificationService.delete(notification.id);

            const notificationArray = notification.read_at
                ? this.allNotifications.read
                : this.allNotifications.unread;

            notificationArray.splice(
                notificationArray.indexOf(notification), 1
            );
        },
        navigate(notification) {
            console.log(notification);
            if (notification.route) {
                this.$router.push(notification.route);
            }
        },
    },
};
</script>
