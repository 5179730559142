<template>
    <div class="y-scrollable-block-layout">
        <h2>Sales/stock <span v-if="!filters.dateRange.startDate && !filters.dateRange.endDate">(YTD)</span></h2>

        <b-form-radio-group v-model="selectedLevel"
                            :options="levelSelectorOptions"
                            buttons button-variant="light"
                            class="btn-group-separate">
        </b-form-radio-group>

        <filter-set :filters="['dateRange', 'productGroup', 'product', 'others']" v-model="filters"
                    @filter-change="handleFilterChange">
        </filter-set>

        <div class="text-right mb-3">
            <button type="button" class="btn btn-primary" @click="showChart = !showChart">Show/hide chart</button>
        </div>

        <sales-and-stock-chart v-if="showChart && productLevelData" :product-level-data="productLevelData"
                               :show="selectedLevel === 'product' ? 'all' : selectedLevel"
                               class="mb-4">
        </sales-and-stock-chart>

        <div class="d-flex justify-content-end mb-1">
            <a href="#" class="text-primary font-weight-bold"
               @click="exportSelectedLevel">Export to XLS</a>
        </div>

        <product-level-table v-if="selectedLevel === 'product' && productLevelData"
                             :data="productLevelData">
        </product-level-table>

        <client-level-table v-else-if="selectedLevel === 'distributor' && distributorLevelData"
                            :data="distributorLevelData" client-type="distributor">
        </client-level-table>

        <client-level-table v-else-if="selectedLevel === 'retailer' && retailerLevelData"
                            :data="retailerLevelData" client-type="retailer">
        </client-level-table>
    </div>
</template>

<script>
import ProductLevelTable from '../components/sales-and-stock/ProductLevelTable';
import ClientLevelTable from '../components/sales-and-stock/ClientLevelTable';
import SalesAndStockChart from '../components/sales-and-stock/SalesAndStockChart';
import StatisticsService from '../../js/services/StatisticsService';
import {downloadBlobResponse} from '../../js/helpers';

export default {
    components: {
        ProductLevelTable,
        ClientLevelTable,
        SalesAndStockChart
    },
    data() {
        return {
            showChart: false,
            selectedLevel: 'product',
            levelSelectorOptions: [
                { text: 'Product level', value: 'product' },
                { text: 'Distributor level', value: 'distributor' },
                // { text: 'Retailer level', value: 'retailer', disabled: true },
            ],
            productLevelData: null,
            distributorLevelData: null,
            retailerLevelData: null,
            filters: {
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
                productGroup: 1,
                continent: null,
                country: null,
                distributor: null,
            },
        };
    },
    watch: {
        async selectedLevel() {
            if (! this[`${this.selectedLevel}LevelData`]) {
                await this.loadDataOfLevel(this.selectedLevel);
            }
        },
    },
    async mounted() {
        await this.loadData(this.selectedLevel);
    },
    methods: {
        async loadData() {
            const promises = [
                this.loadDataOfLevel('product'),
            ];

            if (this.selectedLevel !== 'product') {
                promises.push(this.loadDataOfLevel(this.selectedLevel));
            }

            await Promise.all(promises);
        },
        async loadDataOfLevel(level) {
            this[`${level}LevelData`] = await StatisticsService.getSalesAndStockStats(
                level, this.filters
            );
        },
        async handleFilterChange(change) {
            await this.loadData();
        },
        async exportSelectedLevel() {
            downloadBlobResponse(
                await StatisticsService.getSalesAndStockStats(
                    this.selectedLevel, this.filters, true
                ),
                this.selectedLevel + '_level_sales_and_stock.xlsx'
            );
        },
    },
};
</script>
