<template>
    <div class="y-scrollable-block position-relative overflow-auto">
        <table class="table table-bordered table-has-sticky table-sticky-col table-sticky-row table-layout-fixed">
            <thead>
            <tr>
                <th>Products</th>
                <th>Sales 7F</th>
                <th>Last Year</th>
                <th>Budget</th>
                <th>Sell out D</th>
<!--                <th>Sell out R</th>-->
                <th v-if="showColumns === 'all'">Registered C</th>
                <th v-if="showColumns === 'all'">Stock 7F</th>
                <th>Stock D</th>
                <th v-if="showColumns === 'all'">Stock R</th>
            </tr>
            </thead>
            <tbody>
            <product-level-table-row :data="data.total" :show-columns="showColumns">
                <th>Total</th>
            </product-level-table-row>

            <template v-for="series in data.series">
                <product-level-table-row :data="series.total" :show-columns="showColumns"
                                         :class="['collapsible-row', { 'collapsible-row-open': series.open }]">
                    <th @click="toggleCollapsed(series)">{{ series.series.name }}</th>
                </product-level-table-row>

                <template v-if="series.open">
                    <product-level-table-row v-for="product in series.products" :data="product.statistics"
                                             :key="product.product.id" class="collapsible-row-open"
                                             :show-columns="showColumns">
                        <th class="font-weight-normal">
                            {{ product.product.product_number }}
                            <br>
                            <span style="font-size:66%">{{ product.product.matchcode }}</span>
                        </th>
                    </product-level-table-row>
                </template>
            </template>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
th, td {
    width: 120px;
}
</style>

<script>
import ProductLevelTableRow from './ProductLevelTableRow';

export default {
    components: {
        ProductLevelTableRow,
    },
    props: {
        data: {
            required: true,
            type: Object,
        },
        showColumns: { // all or distributor
            required: false,
            type: String,
            default: 'all',
        },
    },
    methods: {
        toggleCollapsed(series) {
            this.$set(series, 'open', ! series.open);
        },
    },
};
</script>
