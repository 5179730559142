<template>
    <GChart type="ColumnChart" :data="dataTable" :options="options">
    </GChart>
</template>

<script>
import config from '../../../../js/config';

export default {
    props: {
        data: {
            required: true,
            type: Array,
        }
    },
    computed: {
        options() {
            const options = {
                colors: [config.charts.colors.retailer1],
                width: '100%',
                height: 200,
                chartArea: {
                    width: '85%',
                    height: '80%'
                },
                vAxis: {
                    title: 'QTY products',
                },
            };

            return _.merge(options, config.charts.options);
        },
        dataTable() {
            const table = [];

            table.push(['Series', 'Sales']);

            for (const series of this.data) {
                table.push([series.name, series.sales || 0]);
            }

            return table;
        },
    },
};
</script>
