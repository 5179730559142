<template>
    <div id="cockpitLayoutWrapper">
        <the-sidebar id="cockpitSidebar" class="sidebar-visible"></the-sidebar>
        <div id="cockpitContent">
            <router-view></router-view>
        </div>
    </div>
</template>

<style scoped>
/* style in _the_sidebar.scss */
</style>

<script>
import TheSidebar from '../components/TheSidebar';

export default {
    components: {
        TheSidebar,
    },
};
</script>
