<template>
    <tr>
        <slot></slot>
        <td>{{ data.sales_7f_quantity | number }}</td>
        <td><number-value :n="data.sales_7f_quantity_trend" percents></number-value></td>
        <td>{{ (showForecasts ? data.forecasts_quantity : data.budget) | number }}</td>
        <td>{{ data.sales_distributor_quantity | number }}</td>
<!--        <td>{{ data.sales_retailer_quantity | number }}</td>-->
        <td v-if="showColumns === 'all'">{{ data.registered_at_customers | number }}</td>
        <td v-if="showColumns === 'all'">{{ data.stock_7f | number }}</td>
        <td>{{ data.stock_distributor | number }}</td>
        <td v-if="showColumns === 'all'">{{ data.stock_retailer | number }}</td>
    </tr>
</template>

<script>
export default {
    props: {
        data: {
            required: true,
            type: Object,
        },
        showColumns: {
            required: false,
            type: String,
            default: 'all',
        },
    },
    computed: {
        showForecasts() {
            return 'forecasts_quantity' in this.data;
        },
    },
};
</script>
