<template>
    <div class="d-flex flex-column h-100">
        <h2>{{ retailer.name }}</h2>

        <div class="row flex-grow-1">
            <div class="col-3">
                <div class="d-flex flex-column h-100">
                    <key-information :retailer="retailer" tileClass="h-auto mb-3" />

                    <orders :orders="retailer.orders" tileClass="flex-grow-1" />
                </div>
            </div>

            <div class="col-9">
                <reports :retailer="retailer" />
            </div>
        </div>
    </div>
</template>

<script>
import KeyInformation from '../components/retailer-details/KeyInformation';
import Orders from '../components/retailer-details/Orders';
import Reports from '../components/retailer-details/Reports';

export default {
    components: {
        KeyInformation,
        Orders,
        Reports
    },
    data() {
        return {
            retailer: {
                invoicingAddress: {},
                shippingAddress: {},
                countries: [],
                orders: [],
                reports: {
                    flash: {
                        total: {},
                        groups: []
                    },
                    evolution: {
                        total: {},
                        groups: []
                    },
                }
            }
        };
    },
    mounted() {
        this.loadRetailer();
    },
    methods: {
        loadRetailer() {
            this.retailer = {
                name: 'Retailer 1',
                email: 'retailer@7f.com',
                phoneNumber: '+4586372992',
                invoicingAddress: {
                    street: '545 Baker Street 6756',
                    city: 'London',
                    country: 'UK'
                },
                shippingAddress: {
                    street: '600 Baker Street 6800',
                    city: 'London',
                    country: 'UK'
                },
                invoicingCurrency: 'USD',
                shippingMethod: 'Dropshipping',
                countries: ['Japan'],
                partnershipStartDate: '2020. 01. 23.',
                numberOfLogins: 123,
                lastLoginDate: '2020. 10. 20. 08:23:33',
                orders: [
                    { distributor: 'Distributor 1', placedAt: '2020. 01. 05.', status: 'Closed' },
                    { distributor: 'Distributor 2', placedAt: '2020. 01. 14.', status: 'Open' },
                    { distributor: 'Distributor 2', placedAt: '2020. 01. 19.', status: 'Closed' },
                    { distributor: 'Distributor 1', placedAt: '2020. 01. 22.', status: 'Closed' },
                    { distributor: 'Distributor 3', placedAt: '2020. 01. 22.', status: 'Closed' },
                    { distributor: 'Distributor 2', placedAt: '2020. 01. 23.', status: 'Open' },
                    { distributor: 'Distributor 2', placedAt: '2020. 01. 29.', status: 'Open' },
                    { distributor: 'Distributor 3', placedAt: '2020. 01. 29.', status: 'Open' },
                    { distributor: 'Distributor 3', placedAt: '2020. 01. 31.', status: 'Closed' },
                    { distributor: 'Distributor 3', placedAt: '2020. 01. 31.', status: 'Open' }
                ],
                reports: {
                    flash: {
                        total: { name: 'Total', sales: 12, lytd: -0.05, sellOut: 3, customerReg: 77, stock: 34 },
                        groups: [
                            {
                                name: 'M',
                                sales: 12,
                                lytd: -0.05,
                                sellOut: 3,
                                customerReg: 77,
                                stock: 34,
                                products: [
                                    { name: 'SF-M01/01', sales: 12, lytd: -0.05, sellOut: 3, customerReg: 77, stock: 344 },
                                    { name: 'SF-M01/02', sales: 12, lytd: -0.05, sellOut: 3, customerReg: 77, stock: 34 },
                                ]
                            },
                            {
                                name: 'P',
                                sales: 12,
                                lytd: -0.05,
                                sellOut: 3,
                                customerReg: 77,
                                stock: 34,
                                products: [
                                    { name: 'SF-P01/01', sales: 12, lytd: -0.05, sellOut: 3, customerReg: 77, stock: 34 },
                                    { name: 'SF-P01/02', sales: 12, lytd: -0.05, sellOut: 3, customerReg: 77, stock: 34 },
                                ]
                            },
                        ]
                    },
                    evolution: {
                        total: { name: 'Total', '2016': 13, '2017': 0, '2018': 0, '2019': 55, '2020': 56, '2021 FC': 0, '2022 FC': 0 },
                        groups: [
                            {
                                name: 'M',
                                '2016': 13,
                                '2017': 0,
                                '2018': 0,
                                '2019': 55,
                                '2020': 56,
                                '2021 FC': 0,
                                '2022 FC': 0,
                                products: [
                                    { name: 'SF-M01/01', '2016': 13, '2017': 0, '2018': 0, '2019': 55, '2020': 56, '2021 FC': 0, '2022 FC': 0 },
                                    { name: 'SF-M02/01', '2016': 13, '2017': 0, '2018': 0, '2019': 55, '2020': 56, '2021 FC': 0, '2022 FC': 0 },
                                ]
                            },
                            {
                                name: 'P',
                                '2016': 13,
                                '2017': 0,
                                '2018': 0,
                                '2019': 55,
                                '2020': 56,
                                '2021 FC': 0,
                                '2022 FC': 0,
                                products: [
                                    { name: 'SF-P01/01', '2016': 13, '2017': 0, '2018': 0, '2019': 55, '2020': 56, '2021 FC': 0, '2022 FC': 0 },
                                    { name: 'SF-P02/01', '2016': 13, '2017': 0, '2018': 0, '2019': 55, '2020': 56, '2021 FC': 0, '2022 FC': 0 },
                                ]
                            },
                        ]
                    },
                }
            };
        },
    },
};
</script>
