export default new class {
    constructor() {
        this.$_disitributors = null;
    }

    getAllDistributors() {
        if (! this.$_disitributors) {
            this.$_disitributors = axios.get('distributors', { params: { with_continent_id: 1 } })
                .then(response => response.data.distributors);
        }

        return this.$_disitributors;
    }

    async getDistributor(distributorId) {
        return (await axios.get(`cockpit/distributors/${distributorId}`)).data;
    }

    async getRecentOrdersOfDistributor(distributorId) {
        return (await axios.get(`cockpit/distributors/${distributorId}/orders`)).data;
    }
};
