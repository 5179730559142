<template>
    <div class="y-scrollable-block position-relative overflow-auto">
        <table class="table table-bordered table-has-sticky table-sticky-row table-layout-fixed">
            <thead>
            <tr>
                <th class="series-cell"></th>
                <th class="sticky-cell-left-0 z-1">Product number</th>
                <th class="sticky-cell-left-170 z-1">Matchcode</th>
                <th>Zurich</th>
                <th>Hong Kong</th>
                <th>Germany</th>
                <th>Los Angeles</th>
                <th>113 Office</th>
                <th>In Transit</th>
                <th>Others</th>
                <th>Total</th>
            </tr>
            </thead>
            <tbody>
            <stock-consolidation-table-row :data="data.total" :marked="true">
                <th class="series-cell"></th>
                <th class="sticky-cell-left-0">Total</th>
                <th class="sticky-cell-left-170">—</th>
            </stock-consolidation-table-row>

            <template v-for="series in data.series">
                <stock-consolidation-table-row v-for="(product, idx) in series.products"
                                               :data="product.stocks" :key="product.product.id">
                    <th v-if="idx === 0" :rowspan="series.products.length" class="series-cell">
                        <span>{{ series.series.name }}</span>
                    </th>
                    <th class="sticky-cell-left-0">{{ product.product.product_number }}</th>
                    <th class="sticky-cell-left-170">{{ product.product.matchcode }}</th>
                </stock-consolidation-table-row>
            </template>
            </tbody>
        </table>
    </div>
</template>

<style lang="scss" scoped>
th, td {
    width: 170px;
}

th.series-cell {
    width: 46px;
    background-color: #f7f7f7;

    & > span {
        white-space: nowrap;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
}

.sticky-cell-left-170 {
    position: sticky;
    left: 170px;
}
</style>

<script>
import StockConsolidationTableRow from './StockConsolidationTableRow';

export default {
    components: {
        StockConsolidationTableRow,
    },
    props: {
        data: {
            required: true,
            type: Object,
        },
    },
};
</script>
