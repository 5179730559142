export default {
    homeRoute: { name: 'b2b.dashboard' },
    charts: {
        colors: {
            '7f1': '#5843BE',
            '7f2': '#5f8bac',
            'distributor1': '#00ee87',
            'distributor2': '#66b513',
            'retailer1': '#E31837',
            'retailer2': '#f5a784',
        },
        options: {
            fontName: 'Gotham',
            backgroundColor: 'transparent',
            legend: {
                position: 'bottom',
                alignment: 'right',
            },
            vAxis: {
                titleTextStyle: {
                    color: '#7F7F7F',
                },
                textStyle: {
                    color: '#000000',
                },
                baselineColor: '#000000',
                gridlines: {
                    color: '#000000',
                },
                minorGridlines: {
                    count: 0,
                },
            },
        },
    },
};
