<template>
    <div class="d-flex flex-column h-100">
        <h2 v-if="distributor">{{ distributor.name }}</h2>

        <div class="row flex-grow-1">
            <div class="col-3">
                <div class="d-flex flex-column h-100">
                    <key-information :distributor="distributor" tileClass="h-auto mb-3" v-if="distributor" />

                    <orders :orders="orders" tileClass="flex-grow-1" v-if="orders" />
                </div>
            </div>

            <div class="col-9">
                <reports :distributor-id="distributorId" />
            </div>
        </div>
    </div>
</template>

<script>
import KeyInformation from '../components/distributor-details/KeyInformation';
import Orders from '../components/distributor-details/Orders';
import Reports from '../components/distributor-details/Reports';
import DistributorService from '../../js/services/DistributorService';

export default {
    components: {
        KeyInformation,
        Orders,
        Reports
    },
    data() {
        return {
            distributor: null,
            orders: null,
        };
    },
    computed: {
        distributorId() {
            return parseInt(this.$route.params.id);
        },
    },
    async mounted() {
        await Promise.all([
            this.loadDistributor(),
            this.loadOrders(),
        ]);
    },
    methods: {
        async loadDistributor() {
            this.distributor = (await DistributorService.getDistributor(this.distributorId))
                .distributor;
        },
        async loadOrders() {
            this.orders = (await DistributorService.getRecentOrdersOfDistributor(this.distributorId))
                .distributor_orders;
        },
    },
};
</script>
