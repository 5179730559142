<template>
    <table class="table table-bordered table-layout-fixed mb-0">
        <thead>
        <tr>
            <th style="height: 25px;" v-if="priorityDateCol"></th>
            <th colspan="3" style="height: 25px;">{{ title }}</th>
        </tr>
        <tr>
            <th v-if="priorityDateCol">Priority date</th>
            <th style="height: 60px;">{{ nameColTitle }}</th>
            <th style="height: 60px;">Attachment</th>
            <th style="height: 60px;">Date</th>
        </tr>
        </thead>
        <tbody>
            <tr v-for="attachment in attachments">
                <td v-if="priorityDateCol">{{ attachment.priority_date | date }}</td>
                <td>{{ attachment.name }}</td>
                <td>
                        <span v-if="attachment.url" class="cursor-pointer" @click="downloadDocument(attachment)">
                            <inline-svg src="/assets/icons/file--red.svg">
                            </inline-svg>
                        </span>
                </td>
                <td>{{ attachment.date | date }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import {downloadBlobResponse} from "../../../js/helpers";
import StatisticsService from "../../../js/services/StatisticsService";

export default {
    props: {
        title: {
            required: true,
            type: String,
        },
        nameColTitle: {
            required: true,
            type: String,
        },
        attachments: {
            required: true,
            type: Array,
        },
        priorityDateCol: {
            type: Boolean,
        },
    },
    methods: {
        async downloadDocument(attachment) {
            downloadBlobResponse(
                await StatisticsService.downloadFile(attachment.url),
                attachment.file_name
            );
        },
    },
};
</script>
