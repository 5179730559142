<template>
    <div class="tile d-flex flex-column justify-content-between">
        <div class="d-flex justify-content-between align-items-top mb-3">
            <h3 class="mb-3">Key information</h3>
        </div>

        <template v-if="data">
            <div class="row row-cols-4">
                <div class="col">
                    <h3>{{ data.total.first_app_opens | number }}</h3>
                    <p>App Downloads</p>
                </div>
                <div class="col">
                  <key-information-chart :data="histories.first_app_opens" :chart-title="chartTitle" />
                </div>

<!--                <div class="col">-->
<!--                    <h3>n.a.</h3>-->
<!--                    <p>User cancellations</p>-->
<!--                </div>-->
<!--                <div class="col">-->
<!--                  <key-information-chart :data="nonAvailableHistory" :chart-title="chartTitle" />-->
<!--                </div>-->

                <div class="col">
                    <h3>{{ data.total.user_registrations | number }}</h3>
                    <p>User registrations</p>
                </div>
                <div class="col">
                  <key-information-chart :data="histories.user_registrations" :chart-title="chartTitle" />
                </div>

                <div class="col">
                    <h3>{{ data.total.product_registrations | number }}</h3>
                    <p>Product registrations</p>
                </div>
                <div class="col">
                  <key-information-chart :data="histories.product_registrations" :chart-title="chartTitle" />
                </div>

                <div class="col">
                    <h3>{{ data.total.successful_registrations | number }}</h3>
                    <p>Successful registrations</p>
                </div>
                <div class="col">
                  <key-information-chart :data="histories.successful_registrations" :chart-title="chartTitle" />
                </div>

                <div class="col">
                    <h3>{{ data.total.warranty_activations | number }}</h3>
                    <p>Warranty extension activations</p>
                </div>
                <div class="col">
                  <key-information-chart :data="histories.warranty_activations" :chart-title="chartTitle" />
                </div>

                <div class="col">
                    <h3>{{ data.total.unfinished_registrations | number }}</h3>
                    <p>Unfinished registrations</p>
                </div>
                <div class="col">
                  <key-information-chart :data="histories.unfinished_registrations" :chart-title="chartTitle" />
                </div>
            </div>
        </template>
        <b-skeleton-img v-else />
    </div>
</template>

<style scoped>
.row h3 {
  margin-bottom: 0;
  font-weight: 600;
}
</style>

<script>
import KeyInformationChart from './charts/KeyInformationChart'

export default {
    components: {
      KeyInformationChart
    },
    props: {
        data: {
            required: false,
            type: Object,
        },
        chartTitle: {
            required: false,
            type: String,
        }
    },
    data() {
        return {
            nonAvailableHistory: [0],
        };
    },
    computed: {
        histories() {
            const keys = Object.keys(this.data.total);
            const histories = {};

            for (const key of keys) {
                histories[key] = [];
            }

            for (const interval of this.data.intervals) {
                for (const key of keys) {
                    histories[key].push({
                        value: interval[key],
                        label: interval.label,
                    });
                }
            }

            return histories;
        },
    },
};
</script>
