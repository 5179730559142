<template>
    <div :class="['tile', tileClass]">
        <h3 class="mb-2">Key information</h3>

        <div class="d-flex flex-column">
            <div class="info-row">
                <span>Customer NR</span>
                <span>{{ distributor.customer_number }}</span>
            </div>

            <div class="info-row">
                <span>Contacts</span>
            </div>

            <div class="info-row padded">
                <span>E-mail:</span>
                <span class="text-break">{{ distributor.email | dash }}</span>
            </div>

            <div class="info-row padded">
                <span>Mobile:</span>
                <span>{{ distributor.phone | dash }}</span>
            </div>

            <div class="info-row">
                <span>Address</span>
                <span>
                    {{ distributor.street_address | dash }}
                    <template v-if="distributor.street_address_2">
                        <br>{{ distributor.street_address_2 }}
                    </template>
                    <br />
                    {{ distributor.zip }} {{ distributor.city }}
                </span>
            </div>

            <div class="info-row">
                <span>Invoicing currency</span>
                <span>{{ distributor.price_list.currency }}</span>
            </div>

            <div class="info-row">
                <span>Dropshipping</span>
                <span>{{ distributor.dropshipping ? 'Enabled' : 'Disabled' }}</span>
            </div>

            <div class="info-row">
                <span>Country</span>
                <span>{{ distributor.country.name }}</span>
            </div>

            <div class="info-row">
                <span>No of logins (last 3 mts)</span>
                <span>{{ null | number }}</span>
            </div>

            <div class="info-row">
                <span>Last login</span>
                <span>{{ distributor.last_login | datetime | dash }}</span>
            </div>
        </div>
    </div>
</template>

<style scoped>
.info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
}

.info-row.padded {
  margin-left: 15px;
}

.info-row:not(.padded) span:first-child {
  font-weight: 500;
}

.info-row span:last-child {
  text-align: end;
}
</style>

<script>
export default {
    props: {
        distributor: {
            required: true,
            type: Object,
        },
        tileClass: {
            required: false,
            type: String
        }
    }
};
</script>
