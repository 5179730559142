<template>
    <div class="d-flex flex-column h-100">
        <div class="d-flex justify-content-between align-items-center mb-2">
            <h2>Dashboard</h2>

<!--            <filter-set :filters="['dateRange']" v-model="filters" />-->
        </div>

        <div class="row mb-3 flex-grow-1">
            <div class="col-4">
                <div class="tile mh-400">
                    <h3 class="mb-2" @click="navigateTo('b2b.sales-flash')">Daily sales flash (MTD)</h3>

                    <v-select
                        v-model="selectedSalesFlashOptionId"
                        :options="salesFlashOptions"
                        label="text"
                        :reduce="option => option.id"
                        :searchable="false"
                        :clearable="false" />

                    <div class="sales-flash-table-container">
                        <compact-sales-flash-table v-if="salesFlashData" :data="salesFlashData"
                                                   :show="selectedSalesFlashOptionId" />
                        <b-skeleton-table v-else :rows="6" :columns="3"
                                          :table-props="{ bordered: true }" />
                    </div>
                </div>
            </div>

            <div class="col-8">
                <div class="tile mh-400">
                    <div class="d-flex justify-content-between align-items-center mb-4">
                        <h3 @click="navigateTo('b2b.plan')">Master purchase plan</h3>
                        <h5>
                            <template v-if="purchasePlanData">
                                Month Stock:
                                <month-stock-value :month-stock="purchasePlanData.total.month_stock"
                                                   :stock="purchasePlanData.total.stock_7f" />
                            </template>
                            <b-skeleton v-else style="min-width: 150px;"></b-skeleton>
                        </h5>
                    </div>

                    <div class="purchase-plan-table-container">
                        <product-purchase-plan-overview-table v-if="purchasePlanData" :data="purchasePlanData.products" />
                        <b-skeleton-table v-else :rows="7" :columns="4" :table-props="{ bordered: true }" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-4 d-flex flex-column">
                <div class="row mb-2">
                    <div class="col-6">
                        <div class="tile">
                            <h3 class="mb-3" @click="navigateTo('b2b.distributors')">Distributors</h3>

                            <div class="mb-3 cursor-pointer" @click="showOpenWatchOrders">
                                <h3 v-if="miscStats" class="font-weight-bold">{{ miscStats.open_watch_orders_count | number }}</h3>
                                <b-skeleton v-else />
                                <p>Open watch orders</p>
                            </div>

                            <div class="cursor-pointer" @click="showForecasts">
                                <h3 v-if="miscStats" class="font-weight-bold text-danger">{{ miscStats.empty_forecasts_count | number }}</h3>
                                <b-skeleton v-else />
                                <p>Missing forecast months</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="tile">
                            <h3 class="mb-3" @click="navigateTo('b2b.retailers')">Retailers</h3>

                            <div class="mb-3">
                                <h3 class="font-weight-bold">{{ null | number }}</h3>
                                <p>Open watch orders</p>
                            </div>

                            <div>
                                <h3 class="font-weight-bold">{{ null | number }}</h3>
                                <p>Active retailers</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row flex-grow-1">
                    <div class="col-12">
                        <div class="tile">
                            <h3 class="mb-1" @click="navigateTo('b2b.time-to-market')">Time to market (YTD)</h3>

                            <time-to-market-chart v-if="timeToMarketData" :time-to-market-data="timeToMarketData" compact />
                            <b-skeleton-img v-else />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-8">
                <div class="tile">
                    <h3 class="mb-3" @click="navigateTo('b2b.sales-and-stock')">Sales/Stock (YTD)</h3>

                    <b-form-radio-group
                        v-model="selectedSalesLevel"
                        :options="salesLevelOptions"
                        buttons button-variant="light"
                        class="btn-group-separate mb-1" />

                    <sales-and-stock-chart
                        v-if="salesAndStockData" :product-level-data="salesAndStockData" compact
                        :show="selectedSalesLevel === 'product' ? 'all' : selectedSalesLevel" />
                    <b-skeleton-img v-else />
                </div>
            </div>
        </div>

        <b-modal id="open-watch-orders-modal" title="Open watch order deliveries" scrollable ok-only>
            <table class="table table-bordered table-has-sticky table-sticky-row table-layout-fixed" v-if="openWatchOrders">
                <thead>
                <tr>
                    <th>Distributor</th>
                    <th>NR of orders</th>
                    <th>QTY</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="distributor in openWatchOrders">
                    <td>{{ distributor.distributor.short_name }}</td>
                    <td>{{ distributor.orders.orders_count | number }}</td>
                    <td>{{ distributor.orders.orders_quantity | number }}</td>
                    <td>{{ distributor.orders.orders_price | price }}</td>
                </tr>
                </tbody>
            </table>
            <b-skeleton-table v-else :rows="3" :columns="4"
                              :table-props="{ bordered: true }" />
        </b-modal>

        <b-modal id="forecasts-modal" title="Distributor forecasts" scrollable ok-only>
            <table class="table table-bordered table-has-sticky table-sticky-row" v-if="forecasts">
                <thead>
                <tr>
                    <th>Distributor</th>
                    <th v-for="month in forecasts.total.months" class="px-1">
                        {{ month.month | month }}
                    </th>
                </tr>
                </thead>
                <tbody>
                <template v-for="distributor in forecasts.distributors">
                    <tr>
                        <td class="text-left px-1">
                            {{ distributor.distributor.short_name }}
                        </td>
                        <td v-for="month in distributor.months" class="px-1">
                            {{ month.forecasts_quantity | number }}
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
            <b-skeleton-table v-else :rows="3" :columns="6"
                              :table-props="{ bordered: true }" />
        </b-modal>
    </div>
</template>

<style scoped>
h2, h3, h5 {
    margin-bottom: 0;
}

h3 {
    cursor: pointer;
}

.mh-400 {
    min-height: 400px;
}

.sales-flash-table-container {
    margin-top: 12px;
}

.purchase-plan-table-container {
    position: absolute;
    top: 60px;
    bottom: 12px;
    left: 16px;
    right: 16px;
    overflow-y: auto;
}
</style>

<script>
import CompactSalesFlashTable from '../components/sales-flash/CompactSalesFlashTable';
import ProductPurchasePlanOverviewTable from '../components/purchase-plan/ProductPurchasePlanOverviewTable';
import MonthStockValue from '../components/purchase-plan/MonthStockValue';
import TimeToMarketChart from '../components/time-to-market/TimeToMarketChart';
import SalesAndStockChart from '../components/sales-and-stock/SalesAndStockChart';
import StatisticsService from '../../js/services/StatisticsService';

export default {
    components: {
        CompactSalesFlashTable,
        ProductPurchasePlanOverviewTable,
        MonthStockValue,
        TimeToMarketChart,
        SalesAndStockChart,
    },
    data() {
        return {
            filters: {
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
            },

            selectedSalesFlashOptionId: 'sales_7f_in',
            salesFlashOptions: [
                { id: 'sales_7f_ws', text: 'Ordered' },
                { id: 'sales_7f_oc', text: 'Invoiced (open OC)' },
                { id: 'sales_7f_in', text: 'Delivered' },
                { id: 'budget', text: 'Budget' },
                { id: 'forecasts', text: 'Forecast' },
                { id: 'delta', text: 'Delta' },
            ],
            salesFlashData: null,

            purchasePlanData: null,

            selectedSalesLevel: 'product',
            salesLevelOptions: [
                { value: 'product', text: 'Product level'},
                { value: 'distributor', text: 'Distributor level' },
                // { value: 'retailer', text: 'Retailer level', disabled: true },
            ],

            timeToMarketData: null,

            salesAndStockData: null,

            miscStats: null,

            openWatchOrders: null,
            forecasts: null,
        };
    },
    async mounted() {
        await Promise.all([
            this.loadSalesFlashData(),
            this.loadPurchasePlanData(),
            this.loadTimeToMarketData(),
            this.loadSalesAndStockData(),
            this.loadMiscStats(),
        ]);
    },
    methods: {
        navigateTo(name) {
            this.$router.push({ name: name });
        },
        async loadSalesFlashData() {
            this.salesFlashData = await StatisticsService.getSalesFlash({}, true, false, true);
        },
        async loadPurchasePlanData() {
            this.purchasePlanData = await StatisticsService.getProductPurchasePlanOverview({
                productGroup: 1,
            }, true);
        },
        async loadSalesAndStockData() {
            this.salesAndStockData = await StatisticsService.getSalesAndStockStats(
                'product', { productGroup: 1}, false, true
            );
        },
        async loadTimeToMarketData() {
            this.timeToMarketData = await StatisticsService.getTimeToMarketStats({
                productGroup: 1,
            }, false, true)
        },
        async loadMiscStats() {
            this.miscStats = await StatisticsService.getMiscStats(true);
        },
        async showOpenWatchOrders() {
            this.openWatchOrders = null;

            this.$bvModal.show('open-watch-orders-modal');

            this.openWatchOrders = (await StatisticsService.getOpenOCsByDistributors({
                order_product_group_id: 1,
            }, true)).distributors;
        },
        async showForecasts() {
            this.forecasts = null;

            this.$bvModal.show('forecasts-modal');

            this.forecasts = await StatisticsService.getForecastsByDistributors(true);
        }
    },
};
</script>
