import EventBus from '../event-bus';

export default new class {
    constructor() {
        this.$_user = null;

        EventBus.$on('clear-access-token', () => {
            this.$_user = null;
        });
    }

    get() {
        if (! this.$_user) {
            this.$_user = axios.get('users/current')
                .then(response => response.data.user);
        }

        return this.$_user;
    }
};
