<template>
    <table class="table table-bordered table-layout-fixed">
        <thead>
        <tr>
            <th>Products</th>
            <th v-for="column in columns">{{ column }}</th>
        </tr>
        </thead>
        <tbody>
        <sales-evolution-table-row :data="data.total" :columns="columns" />
        <sales-evolution-table-row v-for="group in data.groups" :key="group.name" :data="group" :columns="columns" />
        </tbody>
    </table>
</template>

<style scoped>

.table {
    margin-top: 0;
    margin-bottom: 0;
}
</style>

<script>
import SalesEvolutionTableRow from './SalesEvolutionTableRow';

export default {
    components: {
        SalesEvolutionTableRow
    },
    props: {
        columns: {
            required: true,
            type: Array
        },
        data: {
            required: true,
            type: Object,
        },
    },
};
</script>
