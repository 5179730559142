<template>
    <div class="table-container d-flex">
        <table class="table table-bordered table-layout-fixed">
            <thead ref="table-head-left">
            <tr>
                <th>Products</th>
                <th>AVG 7F sale</th>
                <th>7F Stock</th>
            </tr>
            </thead>
            <tbody>
            <forecasts-table-info-row :data="data.total.additional">
                <th>Total</th>
            </forecasts-table-info-row>

            <template v-for="series in data.series">
                <forecasts-table-info-row :data="series.total.additional"
                                         :class="['collapsible-row', { 'collapsible-row-open': series.open }]">
                    <th @click="toggleCollapsed(series)">{{ series.series.name }}</th>
                </forecasts-table-info-row>

                <template v-if="series.open">
                    <forecasts-table-info-row v-for="product in series.products" :data="product.additional"
                                             :key="product.product.id" class="collapsible-row-open">
                        <th class="font-weight-normal">{{ product.product.product_number }}</th>
                    </forecasts-table-info-row>
                </template>
            </template>
            </tbody>
        </table>

        <div class="scrolling-table-container flex-grow-1" ref="scrolling-table">
            <table class="table table-bordered table-layout-fixed">
                <thead>
                <tr>
                    <th v-for="month in data.total.months" :class="{ 'current-month': month.month === currentMonth }">
                        {{ month.month | month }}
                    </th>
                </tr>
                </thead>
                <tbody>
                <forecasts-table-year-row :data="data.total.months" />

                <template v-for="series in data.series">
                    <forecasts-table-year-row :data="series.total.months"
                                              :class="[{ 'collapsible-row-open': series.open }]" />

                    <template v-if="series.open">
                        <forecasts-table-year-row v-for="product in series.products" :data="product.months"
                                                 :key="product.product.id" class="collapsible-row-open" />
                    </template>
                </template>
                </tbody>
            </table>
        </div>

        <b-icon icon="caret-left-fill" font-scale="2" class="jump-back" @click="jumpBack" ref="jump-back"></b-icon>
        <b-icon icon="caret-right-fill" font-scale="2" class="jump-next" @click="jumpNext"></b-icon>
    </div>
</template>

<style lang="scss" scoped>
.table-container {
    position: relative;

    & > .table:first-child {
        position: relative;
        top: 1px; // a masik tablazatnal a "current month" jelolo miatt nagyobb a felso border
        width: 400px;
    }
}

.scrolling-table-container {
    position: relative;
    width: 100%;
    height: max-content;
    overflow-x: auto;
    overflow-y: hidden;

    .table {
        border-left: none;
        margin-top: 0;
        margin-bottom: 0;

        th, td {
            width: 100px;
        }

        th:first-child {
            border-left: none;
        }

        th.current-month {
            border-left: 3px solid red;
            border-right: 3px solid red;
            border-top: 3px solid red;
        }
    }
}

.jump-back,
.jump-next {
    color: #e31837;
    opacity: .6;
    cursor: pointer;
}

.jump-back:hover,
.jump-next:hover {
    opacity: 1;
}

.jump-back {
    position: absolute;
    top: 8px;
    left: 300px;
}

.jump-next {
    position: absolute;
    top: 8px;
    right: 0;
}
</style>

<script>
import ForecastsTableInfoRow from './ForecastsTableInfoRow';
import ForecastsTableYearRow from './ForecastsTableYearRow';

export default {
    components: {
        ForecastsTableInfoRow,
        ForecastsTableYearRow
    },
    props: {
        data: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            currentMonth: new Date().getMonth() + 1,
        };
    },
    mounted() {
        this.positionJumpBack();
        window.addEventListener('resize', this.positionJumpBack)
    },
    methods: {
        jumpBack() {
            const newPosition = this.$refs['scrolling-table'].scrollLeft - 100;
            this.$refs['scrolling-table'].scrollLeft = Math.floor(newPosition / 100) * 100;
        },
        jumpNext() {
            const newPosition = this.$refs['scrolling-table'].scrollLeft + 100;
            this.$refs['scrolling-table'].scrollLeft = Math.ceil(newPosition / 100) * 100;
        },
        toggleCollapsed(series) {
            this.$set(series, 'open', ! series.open);
        },
        positionJumpBack() {
            this.$refs['jump-back'].style.left = this.$refs['table-head-left'].offsetWidth + 'px';
        },
    },
};
</script>
