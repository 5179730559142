<template>
    <div class="y-scrollable-block position-relative overflow-auto" v-if="data">
        <table class="table table-bordered table-has-sticky table-sticky-row table-sticky-col table-layout-fixed">
            <colgroup>
                <col style="width: 150px;">
                <col style="width: 120px;">
                <col style="min-width: 250px;">
                <col v-if="openState" span="12" style="width: 180px;">
            </colgroup>
            <thead>
            <tr>
                <th></th>
                <th>All QTY</th>
                <th :class="['collapsible-col', { 'collapsible-col-open': openState }]"
                    @click="openState = ! openState">
                    {{ year }} renewal cost
                </th>

                <template v-if="openState">
                    <th v-for="month in 12" class="collapsible-col-open font-weight-normal">
                        {{ month | month }}
                    </th>
                </template>
            </tr>
            </thead>
            <tbody>

            <overview-table-row :data="data.total" :open-state="openState">
                <th>Total</th>
            </overview-table-row>

            <overview-table-row v-for="type in data.types" :data="type.values" :open-state="openState" :key="type.type.id">
                <th>{{ type.type.label }}</th>
            </overview-table-row>

            </tbody>
        </table>
    </div>
    <div v-else class="no-data-available">
        No data available
    </div>
</template>

<style scoped>

</style>

<script>
import OverviewTableRow from './OverviewTableRow';

export default {
    components: {
        OverviewTableRow,
    },
    props: {
        data: {
            required: true,
            type: Object,
        },
        year: {
            required: true,
            type: Number,
        },
    },
    data() {
        return {
            openState: false,
        };
    },
};
</script>
