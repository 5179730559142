<template>
    <div class="y-scrollable-block-layout">
        <h2>Time to Market <span v-if="!filters.dateRange.startDate && !filters.dateRange.endDate">(YTD)</span></h2>

        <filter-set :filters="['dateRange', 'productGroup', 'product', 'others']"
                    v-model="filters" @filter-change="handleFilterChange"
                    :only-with-device-numbers="true">
        </filter-set>

        <div class="text-right mb-3">
            <button type="button" class="btn btn-primary" @click="showChart = !showChart">Show/hide chart</button>
        </div>

        <time-to-market-chart v-if="showChart && timeToMarketData" :time-to-market-data="timeToMarketData"
                               class="mb-4">
        </time-to-market-chart>

        <div class="d-flex justify-content-end mb-1">
            <a href="#" class="text-primary font-weight-bold"
               @click="exportXlsx">Export to XLS</a>
        </div>

        <time-to-market-table v-if="timeToMarketData"
                             :data="timeToMarketData">
        </time-to-market-table>
    </div>
</template>

<script>
import TimeToMarketTable from '../components/time-to-market/TimeToMarketTable';
import TimeToMarketChart from '../components/time-to-market/TimeToMarketChart';
import StatisticsService from '../../js/services/StatisticsService';
import {downloadBlobResponse} from '../../js/helpers';

export default {
    components: {
        TimeToMarketTable,
        TimeToMarketChart,
    },
    data() {
        return {
            showChart: false,
            timeToMarketData: null,
            filters: {
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
                productGroup: 1,
                continent: null,
                country: null,
                distributor: null,
            },
        };
    },
    async mounted() {
        await this.loadTimeToMarketData();
    },
    methods: {
        async loadTimeToMarketData() {
            this.timeToMarketData = await StatisticsService.getTimeToMarketStats(this.filters);
        },
        async handleFilterChange(change) {
            await this.loadTimeToMarketData();
        },
        async exportXlsx() {
            downloadBlobResponse(
                await StatisticsService.getTimeToMarketStats(
                    this.filters, true
                ),
                'time_to_market.xlsx'
            );
        },
    },
};
</script>
