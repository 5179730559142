import EventBus from '../event-bus';
import {formatDate} from '../helpers';

export default new class {
    constructor() {
        this.$_unreadCount = 0;
    }

    async boot() {
        EventBus.$on('got-access-token', async () => {
            this.$_unreadCount = (await axios.get('notifications/count', {__hideSpinner: true})).data.count;

            EventBus.$emit('unread-count-refreshed', this.$_unreadCount);
        });
    }

    get unreadCount() {
        return this.$_unreadCount;
    }

    async index() {
        const notifications = (await axios.get('notifications')).data.notifications;

        for (const notification of notifications) {
            const html = this.$_getHtml(notification);

            if (Array.isArray(html)) {
                notification.html = html[0];
                notification.route = html[1];
            } else {
                notification.html = html;
            }
        }

        return notifications;
    }

    async readTill(notificationId) {
        this.$_unreadCount = (await axios.post(
            `notifications/${notificationId}/read-till`,
            null,
            { __hideSpinner: true }
        )).data.count;

        EventBus.$emit('unread-count-refreshed', this.$_unreadCount);
    }

    async delete(notificationId) {
        await axios.delete(`notifications/${notificationId}`);
    }

    $_getHtml(notification) {
        if (notification.data.event === 'sales') {
            return `Sales of <strong>${notification.data.product}</strong> has reached <strong>${notification.data.sales}</strong>`;
        } else if (notification.data.event === 'certificate_due') {
            return [
                (notification.data.reference ? `IP renewal of ${notification.data.reference}` : 'Renewal of an IP') + 'is due in 4 months (' + formatDate(notification.data.next_renewal) + ')',
                { name: `legal.${notification.data.type}.details`, params: { id: notification.data.id } },
            ];
        } else {
            return notification.text;
        }
    }
};
