<template>
    <div class="y-scrollable-block-layout">
        <h2>Distributors (YTD)</h2>

        <filter-set :filters="['search', 'productGroup', 'product']" v-model="filters"
                    @filter-change="handleFilterChange">
        </filter-set>

        <distributors-table v-if="distributorData"
                             :data="filteredDistributorData">
        </distributors-table>
    </div>
</template>

<script>
import DistributorsTable from '../components/distributors/DistributorsTable';
import StatisticsService from '../../js/services/StatisticsService';

export default {
    components: {
        DistributorsTable,
    },
    data() {
        return {
            distributorData: null,
            filters: {
                search: null,
                productGroup: null,
                product: null,
            },
        };
    },
    computed: {
        filteredDistributorData() {
            if (! this.filters.search) {
                return this.distributorData;
            }

            return this.distributorData.filter(
                d => d.distributor.name.toLowerCase().includes(this.filters.search)
            );
        },
    },
    async mounted() {
        await this.loadDistributorData();
    },
    methods: {
        async loadDistributorData() {
            this.distributorData = (await StatisticsService.getDistributorsWithStats(
                this.filters
            )).distributors;
        },
        async handleFilterChange(change) {
            if (change.filter === 'search') {
                return;
            }

            await this.loadDistributorData();
        },
    },
};
</script>
