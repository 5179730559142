<template>
    <div class="y-scrollable-block-layout">
        <h2>Stock consolidation report</h2>

        <div class="d-flex justify-content-between align-items-end mb-1">
            <filter-set :filters="['productGroup', 'productSeries']"
                        v-model="filters" @filter-change="handleFilterChange">
            </filter-set>

            <a href="#" class="text-primary font-weight-bold"
               @click="exportXlsx">Export to XLS</a>
        </div>

        <stock-consolidation-table v-if="stockConsolidationData"
                                   :data="stockConsolidationData">
        </stock-consolidation-table>
    </div>
</template>

<script>
import StockConsolidationTable from '../components/stock-consolidation/StockConsolidationTable';
import StatisticsService from '../../js/services/StatisticsService';
import {downloadBlobResponse} from '../../js/helpers';

export default {
    components: {
        StockConsolidationTable,
    },
    data() {
        return {
            stockConsolidationData: null,
            filters: {
                productGroup: 1,
                productSeries: null,
            }
        };
    },
    async mounted() {
        await this.loadStockConsolidationData();
    },
    methods: {
        async loadStockConsolidationData() {
            this.stockConsolidationData = await StatisticsService.getStockConsolidation(this.filters);
        },
        async handleFilterChange(change) {
            await this.loadStockConsolidationData();
        },
        async exportXlsx() {
            downloadBlobResponse(
                await StatisticsService.getStockConsolidation(
                    this.filters, true
                ),
                'stock_consolidation.xlsx'
            );
        },
    },
};
</script>
