<template>
    <div class="y-scrollable-block-layout">
        <h2>Daily sales flash <span v-if="!filters.dateRange.startDate && !filters.dateRange.endDate">(MTD)</span></h2>

        <div class="d-flex justify-content-between align-items-end mb-1">
            <filter-set :filters="['dateRange']" v-model="filters" @filter-change="handleFilterChange">
            </filter-set>

            <a href="#" class="text-primary font-weight-bold"
               @click="exportXlsx">Export to XLS</a>
        </div>

        <sales-flash-table v-if="salesFlashData"
                           :data="salesFlashData">
        </sales-flash-table>
    </div>
</template>

<script>
import moment from 'moment';
import SalesFlashTable from '../components/sales-flash/SalesFlashTable';
import StatisticsService from '../../js/services/StatisticsService';
import {downloadBlobResponse} from '../../js/helpers';

export default {
    components: {
        SalesFlashTable,
    },
    data() {
        return {
            salesFlashData: null,
            filters: {
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
            }
        };
    },
    async mounted() {
        await this.loadSalesFlashData();
    },
    methods: {
        async loadSalesFlashData() {
            this.salesFlashData = await StatisticsService.getSalesFlash(this.filters);
        },
        async handleFilterChange(change) {
            await this.loadSalesFlashData();
        },
        async exportXlsx() {
            downloadBlobResponse(
                await StatisticsService.getSalesFlash(
                    this.filters, false, true
                ),
                'sales_flash.xlsx'
            );
        },
    },
};
</script>
