<template>
    <div v-frag>
        <tr :class="{ 'collapsible-row': openable, 'collapsible-row-open': open || isChild }">
            <td :class="['font-weight-semibold', { 'font-weight-normal': isChild }]" @click="toggle()">{{ data.name }}</td>
            <td>{{ data.sales }}</td>
            <td><number-value :n="data.lytd" /></td>
            <td>{{ data.sellOut }}</td>
            <td>{{ data.customerReg }}</td>
            <td>{{ data.stock }}</td>
        </tr>

        <template v-if="open">
            <sales-flash-table-row v-for="product in data.products" :key="product.product_number" :data="product" :isChild="true" />
        </template>
    </div>
</template>

<script>
export default {
    name: 'sales-flash-table-row',
    props: {
        data: {
            required: true,
            type: Object,
        },
        isChild: {
            required: false,
            type: Boolean
        }
    },
    data() {
        return {
            open: false
        };
    },
    computed: {
        openable() {
            return this.data.products;
        }
    },
    methods: {
        toggle() {
            if (this.openable) {
                this.open = !this.open;
            }
        }
    }
};
</script>
