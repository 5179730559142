<template>
    <div class="y-scrollable-block-layout">
        <h2>Retailers</h2>

        <filter-set :filters="['search', 'productGroup', 'product']" v-model="filters"
                    @filter-change="handleFilterChange">
        </filter-set>

        <retailers-table v-if="retailerData"
                         :data="filteredRetailerData">
        </retailers-table>
    </div>
</template>

<script>
import RetailersTable from '../components/retailers/RetailersTable';
import StatisticsService from '../../js/services/StatisticsService';

export default {
    components: {
        RetailersTable,
    },
    data() {
        return {
            retailerData: null,
            filters: {
                search: null,
                productGroup: null,
                product: null,
            },
        };
    },
    computed: {
        filteredRetailerData() {
            if (! this.filters.search) {
                return this.retailerData;
            }

            return this.retailerData.filter(
                r => r.retailer.name.toLowerCase().includes(this.filters.search)
                    || r.retailer.parent.name.toLowerCase().includes(this.filters.search)
            );
        },
    },
    async mounted() {
        await this.loadRetailerData();
    },
    methods: {
        async loadRetailerData() {
            this.retailerData = (await StatisticsService.getRetailersWithStats(
                this.filters
            )).retailers;
        },
        async handleFilterChange(change) {
            if (change.filter === 'search') {
                return;
            }

            await this.loadRetailerData();
        },
    },
};
</script>
