<template>
    <tr>
        <slot></slot>
        <td>{{ data.quantity_all }}</td>
        <td>USD {{ data.renewal_cost | price }} .-</td>

        <template v-if="openState">
            <td v-for="values in data.months">
                USD {{ values.renewal_cost | price }} .-
            </td>
        </template>
    </tr>
</template>

<script>
export default {
    props: {
        data: {
            required: true,
            type: Object,
        },
        openState: {
            required: true,
            type: Boolean,
        },
    },
};
</script>
