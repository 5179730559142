<template>
    <div class="d-flex flex-column h-100">
        <div class="d-flex justify-content-between align-items-center mb-2">
            <h2>B2C App Data</h2>

            <filter-set :filters="['dateRange', 'b2c']" v-model="filters"
                        @filter-change="handleFilterChange" date-picker-opens="left" />
        </div>

        <div class="row mb-2">
            <div class="col-6">
                <key-information :data="reportData.statistics"
                    :chart-title="filters.dateRange.startDate || filters.dateRange.endDate ? 'CUSTOM' : 'ALL TIME'"/>
            </div>

            <div class="col-3">
                <country-ranks :data="reportData.countries" @export="exportXlsxCountries" />
            </div>

            <div class="col-3">
                <product-ranks :data="reportData.products" @export="exportXlsxProducts" />
            </div>
        </div>

        <div class="d-flex mh-400 flex-column flex-grow-1">
            <div class="d-flex justify-content-between align-items-end mb-1">
                <b-form-radio-group
                    v-model="selectedReportType"
                    :options="reportTypes"
                    buttons button-variant="light"
                    class="btn-group-separate btn-group-wide" />

                <a href="#" class="text-primary font-weight-bold"
                   @click="exportXlsx">Export to CSV</a>
            </div>

            <user-info-table :data="reportData.accounts ? reportData.accounts.data : null" @sort="handleAccountsTableSorting" />

            <pagination v-if="reportData.accounts" :data="reportData.accounts" :limit="10" size="small"
                        @pagination-change-page="handleAccountsTablePageChange"
                        class="mt-2"/>
        </div>
    </div>
</template>

<style scoped>
h2 {
    margin-bottom: 0;
}
</style>

<script>
import KeyInformation from '../components/reports/KeyInformation'
import CountryRanks from '../components/reports/CountryRanks'
import ProductRanks from '../components/reports/ProductRanks'
import UserInfoTable from '../components/reports/tables/UserInfoTable'
import StatisticsService from '../../js/services/StatisticsService';
import {downloadBlobResponse} from '../../js/helpers';

export default {
    components: {
        KeyInformation,
        CountryRanks,
        ProductRanks,
        UserInfoTable
    },
    data() {
        return {
            filters: {
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
            },

            selectedReportType: 'demographicInfo',
            reportTypes: [
                { value: 'demographicInfo', text: 'Demographic info'},
                { value: 'appRelatedInfo', text: 'App related info' }
            ],

            reportData: {
                accounts: null,
                statistics: null,
                products: null,
                countries: null,
            },

            accountsSorting: null,
            accountsPage: 1,
        };
    },
    watch: {
        selectedReportType() {
            let thEl;

            if (this.selectedReportType === 'demographicInfo') {
                thEl = document.getElementById('userInfoTable').querySelector('th:first-child');
            } else {
                thEl = document.getElementById('userInfoTable').querySelector('th:nth-child(6)');
            }

            if (thEl) {
                thEl.scrollIntoView({
                    behavior: 'smooth',
                    inline: 'start',
                });
            }
        },
    },
    async mounted() {
        await this.loadData();
    },
    methods: {
        async loadData() {
            this.reportData.accounts = null;
            this.reportData.statistics = null;
            this.reportData.products = null;
            this.reportData.countries = null;

            await Promise.all([
                this.loadAccounts(true),
                this.loadStatistics(),
                this.loadProducts(),
                this.loadCountries(),
            ]);
        },
        async loadAccounts(hideSpinner) {
            this.reportData.accounts = await StatisticsService.getB2CAccounts(
                Object.assign(
                    {},
                    this.filters,
                    this.accountsSorting || {col: 'created_at', dir: 'desc'},
                    {page: this.accountsPage}
                ),
                false,
                hideSpinner
            );
        },
        async loadStatistics() {
            this.reportData.statistics = await StatisticsService.getB2CStatistics(this.filters, true);
        },
        async loadProducts() {
            this.reportData.products = await StatisticsService.getB2CProducts(this.filters, false, true);
        },
        async loadCountries() {
            this.reportData.countries = await StatisticsService.getB2CCountries(this.filters, false, true);
        },
        async handleFilterChange(change) {
            this.accountsPage = 1;

            await this.loadData();
        },
        async exportXlsx() {
            downloadBlobResponse(
                await StatisticsService.getB2CAccounts(
                    this.filters, true, false
                ),
                'b2c_accounts.csv'
            );
        },
        async handleAccountsTableSorting(sorting) {
            this.accountsSorting = sorting;
            await this.loadAccounts();
        },
        async handleAccountsTablePageChange(page) {
            this.accountsPage = page;
            await this.loadAccounts();
        },
        async exportXlsxProducts() {
            downloadBlobResponse(
                await StatisticsService.getB2CProducts(
                    this.filters, true, false
                ),
                'b2c_products.xlsx'
            );
        },
        async exportXlsxCountries() {
            downloadBlobResponse(
                await StatisticsService.getB2CCountries(
                    this.filters, true, false
                ),
                'b2c_countries.xlsx'
            );
        },
    }
};
</script>
