import EventBus from '../event-bus';

export default new class {
    getAccessToken() {
        return sessionStorage.getItem('access_token')
            || localStorage.getItem('access_token');
    }

    async login(email, password, remember) {
        const formData = {
            grant_type: 'password',
            client_id: parseInt(process.env.MIX_OAUTH_CLIENT_ID),
            client_secret: process.env.MIX_OAUTH_CLIENT_SECRET,
            scope: '*',
            username: email,
            password
        };

        const accessToken = (await axios.post(
            `${process.env.MIX_APP_URL}/oauth/token`, formData
        )).data.access_token;

        (remember ? localStorage : sessionStorage).setItem('access_token', accessToken);
        (remember ? sessionStorage : localStorage).removeItem('access_token');

        EventBus.$emit('got-access-token');
    }

    async logout () {
        if (this.getAccessToken()) {
            await axios.post('logout');
        }

        this.clearAccessToken();
    }

    clearAccessToken() {
        sessionStorage.removeItem('access_token');
        localStorage.removeItem('access_token');

        EventBus.$emit('clear-access-token');
    }

    async sendPasswordResetEmail(email) {
        await axios.post(
            'passwords/forgot',
            { client: 'cockpit', email }
        );
    }

    async resetPassword(token, email, password) {
        await axios.post(
            'passwords/reset',
            { client: 'cockpit', token, email, password }
        );
    }
};
