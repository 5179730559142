<template>

    <div v-if="lawyer">
        <h2>{{ heading }}</h2>

        <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
            <form @submit.prevent="handleSubmit(send)">
                <ValidationProvider tag="div" class="form-group" name="name" rules="required" v-slot="{ errors }">
                    <label for="name">* Name</label>
                    <input id="name" type="text" class="form-control" v-model="lawyer.name">
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="country_id" rules="required" v-slot="{ errors }" v-if="countryOptions">
                    <label>* Country</label>
                    <v-select v-model="lawyer.country_id"
                              :options="countryOptions"
                              label="name"
                              :reduce="country => country.id"
                              :clearable="false"
                              :searchable="true">
                    </v-select>
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="email" v-slot="{ errors }">
                    <label for="company">Company</label>
                    <input id="company" type="text" class="form-control" v-model="lawyer.company">
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="address" v-slot="{ errors }">
                    <label for="address">Address</label>
                    <textarea id="address" class="form-control" v-model="lawyer.address"></textarea>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="phone" v-slot="{ errors }">
                    <label for="phone">Phone</label>
                    <input id="phone" type="text" class="form-control" v-model="lawyer.phone">
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="email" v-slot="{ errors }">
                    <label for="email">E-mail</label>
                    <input id="email" type="text" class="form-control" v-model="lawyer.email">
                </ValidationProvider>

                <div class="form-group mb-0">
                    <button type="submit" class="btn btn-primary btn-block">
                        {{ heading }}
                    </button>
                </div>
            </form>
        </ValidationObserver>
    </div>

</template>

<script>
import StatisticsService from '../../../js/services/StatisticsService';
import GeoService from '../../../js/services/GeoService';

export default {
    data() {
        return {
            lawyer: null,
            countryOptions: null,
        };
    },
    computed: {
        heading() {
            return this.lawyerId ? 'Edit Lawyer' : 'Create Lawyer';
        },
        lawyerId() {
            return this.$route.params.id;
        },
    },
    watch: {
        async lawyerId() {
            await this.loadLawyer();
        },
    },
    async mounted() {
        this.countryOptions = await GeoService.getCountries();
        await this.loadLawyer();
    },
    methods: {
        async loadLawyer() {
            this.lawyer = this.lawyerId
                ? await StatisticsService.getLawyer(this.lawyerId)
                : {};
        },
        async send() {
            try {
                if (this.lawyerId) {
                    await StatisticsService.updateLawyer(this.lawyerId, this.lawyer);
                } else {
                    await StatisticsService.createLawyer(this.lawyer);
                }

                await this.$router.push({name: 'legal.lawyer'});
            } catch (e) {
                this.displayFormErrorsOrThrow(e);
            }
        },
    },
};
</script>
