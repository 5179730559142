<template>
    <GChart type="ColumnChart" :data="dataTable" :options="options">
    </GChart>
</template>

<script>
import config from '../../../js/config';

export default {
    props: {
        productLevelData: {
            required: true,
            type: Object,
        },
        show: {
            required: false,
            type: String,
            default: 'all',
        },
        compact: {
            required: false,
            type: Boolean,
            default: false,
        }
    },
    computed: {
        sliceIdx() {
            if (this.show === '7f') {
                return { start: 0, end: 2 };
            } else if (this.show === 'distributor') {
                return { start: 2, end: 4 };
            } else if (this.show === 'retailer') {
                return { start: 4, end: 6 };
            } else {
                return { start: 0, end: 6 };
            }
        },
        options() {
            const options = {
                colors: [
                    config.charts.colors['7f1'], config.charts.colors['7f2'],
                    config.charts.colors.distributor1, config.charts.colors.distributor2,
                    config.charts.colors.retailer1, config.charts.colors.retailer2,
                ].slice(this.sliceIdx.start, this.sliceIdx.end),
                vAxis: {
                    title: 'Quantity',
                },
                width: '100%',
                height: 300,
            };

            if (this.compact) {
                options.chartArea = {
                    width: '85%',
                    height: '80%',
                };
            }

            return _.merge(options, config.charts.options);
        },
        dataTable() {
            const table = [];

            const headers = [
                '7F stock', '7F sales',
                'Distributor stock', 'Distributor sales',
                // 'Retailer stock', 'Retailer sales',
            ].slice(this.sliceIdx.start, this.sliceIdx.end);

            headers.unshift('Series');

            table.push(headers);

            for (const series of this.productLevelData.series) {
                const row = [
                    series.total.stock_7f || 0,
                    series.total.sales_7f_quantity || 0,
                    series.total.stock_distributor || 0,
                    series.total.sales_distributor_quantity || 0,
                    // series.total.stock_retailer || 0,
                    // series.total.sales_retailer_quantity || 0,
                ].slice(this.sliceIdx.start, this.sliceIdx.end);

                row.unshift(series.series.name);

                table.push(row);
            }

            return table;
        },
    },
};
</script>
