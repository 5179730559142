import EventBus from './event-bus';
import AuthService from './services/AuthService';

export function handleError(err, vm, info) {
    if (err.config && err.config.transformRequest) {
        // Supposing it's an Axios error

        const status = err.response ? err.response.status : 500;
        let mainMessage;
        let messageList = [];

        switch (status) {
            case 401:
                AuthService.clearAccessToken();
                vm.$router.replace({ name: 'auth.login' });
                break;
            case 403:
                mainMessage = 'This action is unauthorized.';
                break;
            case 404:
                mainMessage = 'The resource could not be found.';
                break;
            case 422:
                mainMessage = 'The given data was invalid.';

                if (err.response.data && err.response.data.errors) {
                    for (const field of Object.getOwnPropertyNames(err.response.data.errors)) {
                        for (const message of err.response.data.errors[field]) {
                            messageList.push(message);
                        }
                    }
                }

                break;
            default:
                mainMessage = 'Unexpected error occurred.';
                break;
        }

        if (err.response.data && err.response.data.message) {
            mainMessage = err.response.data.message;
        }

        EventBus.$emit('show-error-modal', { mainMessage, messageList });
    }
}
