import { render, staticRenderFns } from "./ProductRankTable.vue?vue&type=template&id=2216d4fa&scoped=true&"
import script from "./ProductRankTable.vue?vue&type=script&lang=js&"
export * from "./ProductRankTable.vue?vue&type=script&lang=js&"
import style0 from "./ProductRankTable.vue?vue&type=style&index=0&id=2216d4fa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2216d4fa",
  null
  
)

export default component.exports