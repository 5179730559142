<template>
    <div class="table-container flex-grow-1">
        <table class="table table-bordered table-has-sticky table-sticky-row table-layout-fixed">
            <thead>
            <tr>
                <th>Distributor</th>
                <th>Places at</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="order in data">
                <td>{{ order.distributor }}</td>
                <td>{{ order.placedAt }}</td>
                <td>{{ order.status }}</td>
            </tr>
            </tbody>
        </table>
    </div>     
</template>

<style scoped>
.table-container {
    position: relative;
    overflow-y: auto;
}

.table {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    overflow-y: auto;

    margin-top: 0;
    margin-bottom: 0;
}
</style>

<script>
export default {
    props: {
        data: {
            required: true,
            type: Array,
        },
    },
};
</script>
