<template>
    <div class="table-container flex-grow-1">
        <table class="table table-bordered table-has-sticky table-sticky-row table-layout-fixed">
            <thead>
            <tr>
                <th>Order NR</th>
                <th>Order QTY</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="order in orders">
                <td>{{ order[`${order.highest_prio_voucher}_number`] }}</td>
                <td>{{ order.quantity }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
.table-container {
    position: relative;
    overflow-y: auto;
}

.table {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    overflow-y: auto;

    margin-top: 0;
    margin-bottom: 0;
}
</style>

<script>
export default {
    props: {
        orders: {
            required: true,
            type: Array,
        },
    },
};
</script>
