<template>
    <div class="card-body">
        <h1 class="card-title mb-4">
            <span style="font-size: 60%">Forgotten password</span>
        </h1>

        <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
            <form class="form-horizontal" @submit.prevent="handleSubmit(sendPasswordResetEmail)">
                <ValidationProvider tag="div" class="form-group" name="email" rules="required|email" v-slot="{ errors }">
                    <input type="text" class="form-control" placeholder="E-mail" v-model="formData.email">
                    <div class="text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <div class="form-group mb-0">
                    <button type="submit" class="btn btn-primary btn-block">
                        Request password reset
                    </button>
                </div>
            </form>
        </ValidationObserver>

        <simple-modal ref="successModal" icon="success">
            We have just sent you an e-mail with a password reset link.
        </simple-modal>
    </div>
</template>

<script>
import AuthService from '../../../js/services/AuthService';

export default {
    data() {
        return {
            formData: {},
        };
    },
    methods: {
        async sendPasswordResetEmail() {
            try {
                await AuthService.sendPasswordResetEmail(
                    this.formData.email
                );
            } catch (e) {
                this.displayFormErrorsOrThrow(e);
                return;
            }

            this.$refs.successModal.open();

            await this.$router.replace({name: 'auth.login'});
        },
    },
}
</script>
