<template>
    <div :class="['tile d-flex flex-column', tileClass]">
        <h3 class="mb-3">Reports</h3>

        <b-form-radio-group
            v-model="selectedReportType"
            :options="reportTypes"
            buttons button-variant="light"
            class="btn-group-separate btn-group-wide mb-1" />

        <div class="mb-2">
            <filter-set
                v-model="filters" @filter-change="reloadCurrentlySelected"
                :filters="['dateRange', 'productGroup']" />
        </div>

        <div class="mb-3">
            <template v-if="selectedReportType === 'salesAndStock' && salesAndStockData">
                <sales-and-stock-chart :product-level-data="salesAndStockData" show="distributor" :compact="true" />
            </template>

            <template v-if="selectedReportType === 'salesEvolution' && salesEvolutionData">
                <sales-evolution-chart :data="salesEvolutionData" show="7f" :compact="true" />
            </template>

            <template v-if="selectedReportType === 'salesForecast' && forecastData">
                <forecasts-chart :data="forecastData" />
            </template>
        </div>

        <div class="d-flex justify-content-end mb-1">
            <a href="#" class="text-primary font-weight-bold"
               @click="exportSelectedReport">Export to XLS</a>
        </div>

        <div class="flex-grow-1">
            <template v-if="selectedReportType === 'salesAndStock' && salesAndStockData">
                <sales-and-stock-table :data="salesAndStockData" show-columns="distributor" />
            </template>

            <template v-if="selectedReportType === 'salesEvolution' && salesEvolutionData">
                <sales-evolution-table :data="salesEvolutionData" />
            </template>

            <template v-if="selectedReportType === 'salesForecast' && forecastData">
                <forecasts-table :data="forecastData" />
            </template>
        </div>
    </div>
</template>

<script>
import SalesAndStockChart from '../sales-and-stock/SalesAndStockChart';
import SalesEvolutionChart from '../sales-evolution/SalesEvolutionChart';
import ForecastsChart from '../forecasts/ForecastsChart';
import SalesAndStockTable from '../sales-and-stock/ProductLevelTable';
import SalesEvolutionTable from '../sales-evolution/SalesEvolutionTable';
import ForecastsTable from '../forecasts/ForecastsTable';
import StatisticsService from '../../../js/services/StatisticsService';
import {downloadBlobResponse} from '../../../js/helpers';

export default {
    components: {
        SalesAndStockChart,
        SalesEvolutionChart,
        ForecastsChart,
        SalesAndStockTable,
        SalesEvolutionTable,
        ForecastsTable
    },
    props: {
        distributorId: {
            required: true,
            type: Number,
        },
        tileClass: {
            required: false,
            type: String
        }
    },
    data() {
        return {
            selectedReportType: 'salesAndStock',
            reportTypes: [
                { value: 'salesAndStock', text: 'Sales flash'},
                { value: 'salesEvolution', text: 'Sales evolution' },
                { value: 'salesForecast', text: 'Forecast' },
            ],
            filters: {
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
                productGroup: 1,
            },
            salesAndStockData: null,
            salesEvolutionData: null,
            forecastData: null,
        };
    },
    computed: {
        completeFilters() {
            return Object.assign({}, this.filters, { distributor: this.distributorId, forecasts: 1 });
        },
    },
    watch: {
        async selectedReportType() {
            if (this.selectedReportType === 'salesAndStock' && ! this.salesAndStockData) {
                await this.loadSalesAndStockData();
            } else if (this.selectedReportType === 'salesEvolution' && ! this.salesEvolutionData) {
                await this.loadSalesEvolutionData();
            } else if (this.selectedReportType === 'salesForecast' && ! this.forecastData) {
                await this.loadForecastsData();
            }
        },
        async distributorId() {
            await this.reloadCurrentlySelected();
        },
    },
    async mounted() {
        await this.reloadCurrentlySelected();
    },
    methods: {
        async reloadCurrentlySelected() {
            if (this.selectedReportType === 'salesAndStock') {
                await this.loadSalesAndStockData();
                this.salesEvolutionData = null;
                this.forecastData = null;
            } else if (this.selectedReportType === 'salesEvolution') {
                await this.loadSalesEvolutionData();
                this.salesAndStockData = null;
                this.forecastData = null;
            } else if (this.selectedReportType === 'salesForecast') {
                await this.loadForecastsData();
                this.salesAndStockData = null;
                this.salesEvolutionData = null;
            }
        },
        async loadSalesAndStockData() {
            this.salesAndStockData = await StatisticsService.getSalesAndStockStats(
                'product', this.completeFilters
            );
        },
        async loadSalesEvolutionData() {
            this.salesEvolutionData = await StatisticsService.getSalesEvolution(
                this.completeFilters
            );
        },
        async loadForecastsData() {
            this.forecastData = await StatisticsService.getForecasts(
                this.completeFilters
            );
        },
        async exportSelectedReport() {
            if (this.selectedReportType === 'salesAndStock') {
                downloadBlobResponse(
                    await StatisticsService.getSalesAndStockStats(
                        'product', this.completeFilters, true
                    ),
                    'sales_and_stock_of_distributor.xlsx'
                );
            } else if (this.selectedReportType === 'salesEvolution') {
                downloadBlobResponse(
                    await StatisticsService.getSalesEvolution(
                        this.completeFilters, true
                    ),
                    'sales_evolution_for_distributor.xlsx'
                );
            } else if (this.selectedReportType === 'salesForecast') {
                downloadBlobResponse(
                    await StatisticsService.getForecasts(
                        this.completeFilters, true
                    ),
                    'forecasts_for_distributor.xlsx'
                );
            }
        },
    },
};
</script>
