<template>

    <div>
        <h2>Import IPCs</h2>

        <ValidationObserver v-if="! result" v-slot="{ handleSubmit }" ref="formValidator">
            <form @submit.prevent="handleSubmit(send)">
                <ValidationProvider tag="div" class="form-group" name="type" rules="required" v-slot="{ errors }">
                    <label>* Type</label>
                    <v-select v-model="formData.type"
                              :options="['Trademark', 'Design']"
                              :clearable="false"
                              :searchable="false">
                    </v-select>
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="import_mode" rules="required" v-slot="{ errors }">
                    <label>* Import mode</label>
                    <v-select v-model="formData.import_mode"
                              :options="modeOptions"
                              label="label"
                              :reduce="m => m.id"
                              :clearable="false"
                              :searchable="false">
                    </v-select>
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider v-if="formData.import_mode && formData.import_mode !== 1" tag="div" class="form-group" name="import_mode" rules="required" v-slot="{ errors }">
                    <label>* Unique key (this attribute will be used to match the IPCs in the Cockpit with the IPCs in the Excel)</label>
                    <v-select v-model="formData.key_attribute"
                              :options="keyOptions"
                              label="label"
                              :reduce="k => k.id"
                              :clearable="false"
                              :searchable="false">
                    </v-select>
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" ref="fileValProvider" class="form-group" name="file" rules="required" v-slot="{ validate, errors }">
                    <label for="file">* XLSX file</label>
                    <input id="file" type="file" class="form-control" ref="fileInput" @change="validate">
                    <div class="form-text text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <div class="mt-3 alert alert-info" v-if="formData.type">
                    <p><strong>The Excel file must have the following structure:</strong></p>

                    <p>The first IPC must be in row 5.</p>

                    <p>Columns:</p>

                    <template v-if="formData.type === 'Trademark'">
                        <ul>
                            <li>A: Trademark</li>
                            <li>B: Country (two-letter ISO code)</li>
                            <li>C: Ctype (NAT or IR)</li>
                            <li>D: Class (separated by commas if there are more)</li>
                            <li>E: Status (one of: Granted, Abandoned, Filed, Lapsed, Rejected, Partly refused, Preniminatly partly refused, Pending litigation)</li>
                            <li>F: Application number</li>
                            <li>G: Application date</li>
                            <li>I: Registration number</li>
                            <li>J: First registration date</li>
                            <li>L: Priority number</li>
                            <li>M: Priority date</li>
                            <li>P: (optional) Upcoming renewal date</li>
                            <li>Q: Past renewal date</li>
                            <li>S: Renewal cost</li>
                            <li>T: (optional) Deadline date</li>
                            <li>V: Owner</li>
                            <li>W: Comment</li>
                            <li>X: Periodicity</li>
                            <li>Y: Number of years from registration until deadline</li>
                        </ul>
                    </template>

                    <template v-if="formData.type === 'Design'">
                        <ul>
                            <li>A: Design</li>
                            <li>B: Country (two-letter ISO code)</li>
                            <li>C: Ctype (NAT or IR)</li>
                            <li>D: Status (one of: Granted, Abandoned, Filed, Lapsed, Rejected, Partly refused, Preniminatly partly refused, Pending litigation)</li>
                            <li>E: Application number</li>
                            <li>F: Application date</li>
                            <li>H: Registration number</li>
                            <li>I: First registration date</li>
                            <li>K: Priority number</li>
                            <li>L: Priority date</li>
                            <li>O: (optional) Upcoming renewal date</li>
                            <li>P: Extention</li>
                            <li>Q: (optional) Deadline date</li>
                            <li>R: Past renewal date</li>
                            <li>T: Renewal cost</li>
                            <li>U: Owner</li>
                            <li>V: Comment</li>
                            <li>W: Periodicity</li>
                            <li>X: Number of years from registration until deadline</li>
                        </ul>
                    </template>
                </div>

                <div class="form-group mb-0">
                    <button type="submit" class="btn btn-primary btn-block">
                        Run
                    </button>
                </div>
            </form>
        </ValidationObserver>
        <div v-else>
            <h3>Import result</h3>

            <p><strong>Reading input Excel file:</strong></p>

            <ul>
                <li v-for="logItem in result.read_log">
                    {{ logItem }}
                </li>
            </ul>

            <p><strong>Importing:</strong></p>

            <ul>
                <li v-for="logItem in result.import_log">
                    {{ logItem }}
                </li>
            </ul>

            <button type="submit" class="btn btn-primary btn-block mt-4" @click="result = null">
                Import a new file
            </button>
        </div>
    </div>

</template>

<script>
import StatisticsService from '../../../js/services/StatisticsService';
import LegalService from '../../../js/services/LegalService';

export default {
    data() {
        return {
            formData: {},
            keyOptions: [
                { id: 'application_number', label: 'Application number' },
                { id: 'registration_number', label: 'Registration number' },
                { id: 'priority_number', label: 'Priority number' },
            ],
            modeOptions: [
                { id: 1, label: 'Add all IPCs' },
                { id: 2, label: 'Add only missing IPCs' },
                { id: 3, label: 'Add missing and update existing IPCs', },
                { id: 4, label: 'Add, update, delete IPCs according to Excel' },
            ],
            result: null,
        };
    },
    methods: {
        async send() {
            const formData = new FormData();

            formData.append('type', this.formData.type.toLowerCase());
            formData.append('import_mode', this.formData.import_mode);
            formData.append('file', this.$refs.fileInput.files[0]);

            if (this.formData.key_attribute) {
                formData.append('key_attribute', this.formData.key_attribute);
            }

            try {
                this.result = await StatisticsService.importIpcs(formData);
                await LegalService.refresh();
            } catch (e) {
                this.displayFormErrorsOrThrow(e);
            }
        },
    },
};
</script>
