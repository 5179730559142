<template>
    <div v-frag>
        <tr :class="{ 'collapsible-row': openable, 'collapsible-row-open': open || isChild }">
            <td :class="['font-weight-semibold', { 'font-weight-normal': isChild }]" @click="toggle()">{{ data.name }}</td>
            <td v-for="column in columns">{{ data[column] }}</td>
        </tr>

        <template v-if="open">
            <sales-evolution-table-row v-for="product in data.products" :key="product.product_number" :data="product" :columns="columns" :isChild="true" />
        </template>
    </div>
</template>

<script>
export default {
    name: 'sales-evolution-table-row',
    props: {
        data: {
            required: true,
            type: Object,
        },
        columns: {
            required: true,
            type: Array,
        },
        isChild: {
            required: false,
            type: Boolean
        }
    },
    data() {
        return {
            open: false
        };
    },
    computed: {
        openable() {
            return this.data.products;
        }
    },
    methods: {
        toggle() {
            if (this.openable) {
                this.open = !this.open;
            }
        }
    }
};
</script>
