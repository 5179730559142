export default new class {
    constructor() {
        this.$_continents = null;
        this.$_countries = null;
        this.$_b2cCcities = null;
    }

    getContinents() {
        if (! this.$_continents) {
            this.$_continents = axios.get('continents')
                .then(response => response.data.continents);
        }

        return this.$_continents;
    }

    getCountries() {
        if (! this.$_countries) {
            this.$_countries = axios.get('countries')
                .then(response => response.data.countries);
        }

        return this.$_countries;
    }

    getB2cCities() {
        if (! this.$_b2cCcities) {
            this.$_b2cCcities = axios.get('cockpit/b2c/cities')
                .then(response => response.data.cities);
        }

        return this.$_b2cCcities;
    }
};
