<template>
    <div class="card-body">
        <h1 class="card-title mb-4">
            <span style="font-size: 60%">{{ pageTitle }}</span>
        </h1>

        <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
            <form class="form-horizontal" @submit.prevent="handleSubmit(resetPassword)">
                <ValidationProvider tag="div" class="form-group" name="email" rules="required|email" v-slot="{ errors }">
                    <input type="text" class="form-control" placeholder="E-mail" v-model="formData.email">
                    <div class="text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" name="password" rules="required|min:5|confirmed:password_confirmation" v-slot="{ errors }">
                    <input type="password" class="form-control" :placeholder="inputPlaceholder" v-model="formData.password">
                    <div class="text-danger">{{ errors[0] }}</div>
                </ValidationProvider>

                <ValidationProvider tag="div" class="form-group" vid="password_confirmation">
                    <input type="password" class="form-control" :placeholder="`${inputPlaceholder} again`" v-model="formData.password_confirmation">
                </ValidationProvider>

                <div class="form-group mb-0">
                    <button type="submit" class="btn btn-primary btn-block">
                        {{ buttonLabel }}
                    </button>
                </div>
            </form>
        </ValidationObserver>

        <simple-modal ref="successModal" icon="success" :message="successModalText">
        </simple-modal>
    </div>
</template>

<script>
import AuthService from '../../../js/services/AuthService';
import config from '../../../js/config';

export default {
    data() {
        return {
            formData: {},
        };
    },
    computed: {
        isSetup() {
            return this.$route.meta && this.$route.meta.setup;
        },
        pageTitle() {
            return this.isSetup ? 'Set up password' : 'Reset password';
        },
        inputPlaceholder() {
            return this.isSetup ? 'Your password' : 'New password';
        },
        buttonLabel() {
            return this.isSetup ? 'Finish registration' : 'Reset password';
        },
        successModalText() {
            return this.isSetup
                ? 'You have successfully finished the registration.'
                : 'Your password has been successfully reset.';
        },
    },
    methods: {
        async resetPassword() {
            try {
                await AuthService.resetPassword(
                    this.$route.params.token, this.formData.email, this.formData.password
                );
            } catch (e) {
                this.displayFormErrorsOrThrow(e);
                return;
            }

            this.$refs.successModal.open();

            await this.$router.replace({name: 'auth.login'});
        },
    },
}
</script>
