<template>
    <GChart v-if="data" type="GeoChart" :data="dataTable" :options="options" :settings="{ packages: ['geochart'] }">
    </GChart>
    <b-skeleton-img v-else />
</template>

<script>
import config from '../../../../js/config';

export default {
    props: {
        data: {
            required: false,
            type: Array,
        }
    },
    computed: {
        options() {
            const options = {
                colors: [config.charts.colors['7f1']],
                width: '100%',
                height: 170,
                top: 0,
                chartArea: {
                    width: '100%',
                    height: '100%'
                },
                hAxis: {
                    baselineColor: 'none',
                    ticks: [],
                    title: ''
                },
                vAxis: {
                    baselineColor: 'none',
                    ticks: []
                }
            };

            return _.merge(options, config.charts.options);
        },
        dataTable() {
            const table = [];

            table.push(['Country', 'Popularity']);

            if (this.data) {
                for (const record of this.data) {
                    table.push([record.id, record.count]);
                }
            }

            return table;
        },
    },
};
</script>
