<template>
    <GChart type="ColumnChart" :data="dataTable" :options="options">
    </GChart>
</template>

<script>
import config from '../../../js/config';
import {MONTH_NAMES} from '../../../js/helpers';

export default {
    props: {
        data: {
            required: true,
            type: Object,
        },
        compact: {
            required: false,
            type: Boolean,
            default: false,
        }
    },
    computed: {
        options() {
            const options = {
                colors: [
                    config.charts.colors.distributor2,
                ],
                vAxis: {
                    title: 'Quantity',
                },
                width: '100%',
                height: 300,
            };

            if (this.compact) {
                options.chartArea = {
                    width: '85%',
                    height: '80%',
                };
            }

            return _.merge(options, config.charts.options);
        },
        dataTable() {
            const table = [];

            const headers = [
                'Forecasts',
            ];

            headers.unshift('Month');

            table.push(headers);

            for (const month of this.data.total.months) {
                const row = [
                    month.forecasts_quantity || 0,
                ];

                row.unshift(MONTH_NAMES[month.month]);

                table.push(row);
            }

            return table;
        },
    },
};
</script>
