<template>
    <div class="y-scrollable-block-layout">
        <h2>Overview</h2>

        <div class="row align-items-center">
            <div class="col-auto mb-2">
                <v-select style="min-width: 190px;"
                          v-model="selYear" :clearable="false" :searchable="false"
                          :options="yearOptions">
                </v-select>
            </div>
        </div>

        <div class="d-flex justify-content-end mb-1">
            <router-link :to="{ name: 'legal.ipc.import' }" class="text-primary font-weight-bold mr-4" v-if="canManage">
                Import
            </router-link>
            <a href="#" class="text-primary font-weight-bold"
               @click="exportXlsx">Export to XLS</a>
        </div>

        <overview-table v-if="data" :data="data" :year="selYear" />
    </div>
</template>

<script>
import CurrentUserService from '../../../js/services/CurrentUserService';
import OverviewTable from '../../components/legal/OverviewTable';
import StatisticsService from '../../../js/services/StatisticsService';
import {downloadBlobResponse} from '../../../js/helpers';

export default {
    components: {
        OverviewTable,
    },
    data() {
        return {
            data: null,
            selYear: new Date().getFullYear(),
            canManage: false,
        };
    },
    computed: {
        yearOptions() {
            const currYear = new Date().getFullYear();
            const options = [];

            for (let i = 0; i <= 4; ++i) {
                options.push(currYear + i);
            }

            return options;
        },
    },
    watch: {
        async selYear() {
            await this.loadOverview();
        },
    },
    async mounted() {
        this.canManage = (await CurrentUserService.get()).can.includes('manage-ipcs');
        await this.loadOverview();
    },
    methods: {
        async loadOverview() {
            this.data = await StatisticsService.getIPCOverview(this.selYear);
        },
        async exportXlsx() {
            downloadBlobResponse(
                await StatisticsService.getIPCOverview(
                    this.selYear, true
                ),
                'ipc_overview.xlsx'
            );
        },
    },
};
</script>
