<template>
    <div :class="['tile mh-400 d-flex flex-column', tileClass]">
        <h3 class="mb-2">Open OC</h3>

        <orders-table :orders="orders" />
    </div>
</template>

<script>
import OrdersTable from './tables/OrdersTable'

export default {
    components: {
        OrdersTable
    },
    props: {
        orders: {
            required: true,
            type: Array,
        },
        tileClass: {
            required: false,
            type: String
        }
    }
};
</script>
